<template>
  <div>
    <el-dialog
      title="添加设备"
      :visible.sync="dialogVisible"
      width="710px"
      :show-close="false"
      :close-on-click-modal="false"
      custom-class="dialog"
    >
      <el-form ref="form" :rules="rules" :model="formInline" label-width="100px" style="padding: 0">
        <div style="float: left">
          <el-form-item :label="$t('searchModule.Equipment_name')" prop="equipmentName">
            <el-input
              :maxlength="20"
              v-model="formInline.equipmentName"
              placeholder="请输入"
              class="formWidth"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Equipment_type')" prop="equipmentType">
            <el-select v-model="formInline.equipmentType" placeholder="请选择">
              <el-option
                :label="value.desc"
                :value="value.code"
                :key="value.code"
                v-for="value in equipmentType"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备功能" prop="traceTag">
            <el-select v-model="formInline.traceTag" placeholder="请选择" @change="functionChange">
              <el-option
                :label="value.desc"
                :value="value.code"
                :key="value.code"
                v-for="value in traceTag"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div style="float: left">
          <el-form-item label="设备编码" prop="equipmentCode">
            <el-input
              :maxlength="50"
              v-model="formInline.equipmentCode"
              placeholder="请输入"
              class="formWidth"
            ></el-input>
          </el-form-item>

          <el-form-item label="设备品牌" prop="brandId">
            <el-select v-model="formInline.brandId" placeholder="请选择">
              <el-option
                :label="value.brandFullName"
                :value="value.brandId"
                :key="value.brandId"
                v-for="value in brandList"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('searchModule.remarks')" prop="description">
            <el-input
              placeholder="请输入"
              :maxlength="50"
              v-model="formInline.description"
              class="formWidth"
            ></el-input>
          </el-form-item>
        </div>
        <div style="clear: both"></div>
        <el-form-item v-show="formInline.traceTag !== ''" label="对应泊位号">
          <el-transfer
            target-order="push"
            :titles="['未选择', '已选择']"
            filterable
            ref="transfer"
            filter-placeholder="请输入内容"
            :props="{ label: 'berthCode', key: 'berthId' }"
            v-model="formInline.equipmentBerth"
            :data="data2"
          >
          </el-transfer>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="sure">{{ $t("button.preservation") }}</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      rules: {
        traceTag: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        equipmentCode: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur",
          },
        ],
        equipmentName: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur",
          },
        ],
        equipmentType: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        brandId: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        equipmentBerth: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
      formInline: {
        operationId: this.$route.query.operationId,
        parkId: this.$route.query.parkId,
        equipmentCode: "",
        equipmentName: "",
        equipmentType: "",
        brandId: "",
        traceTag: "",
        description: "",
        equipmentBerth: [],
        equipmentBar: "",
      },
      data2: [],
    };
  },
  props: ["equipmentType", "traceTag", "brandList"],
  methods: {
    open() {
      this.$refs.transfer && this.$refs.transfer.clearQuery("left");
      this.$refs.transfer && this.$refs.transfer.clearQuery("right");
      this.$refs.form && this.$refs.form.resetFields();
      this.dialogVisible = true;
      this.formInline.equipmentBerth = [];
    },
    functionChange(item) {
      this.formInline.equipmentBerth = [];
      this.$axios
        .get("/acb/2.0/equipment/listUnboundEquipmentBerths", {
          data: {
            parkId: this.$route.query.parkId,
            traceTag: item,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.data2 = res.value;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    sure() {
      this.$refs.form.validate((valid) => {
        let data = {
          ...this.formInline,
        };
        data.equipmentBerth = this.formInline.equipmentBerth.join(",");
        if (valid) {
          this.$axios
            .post("/acb/2.0/equipment/add", {
              data: data,
            })
            .then((res) => {
              if (res.state == 0) {
                this.dialogVisible = false;
                this.$emit("searchData");
              } else {
                this.$alert(res.desc, this.$t("pop_up.Tips"), {
                  confirmButtonText: this.$t("pop_up.Determine"),
                });
              }
            });
        } else {
        }
      });
    },
  },
  mounted() {},
};
</script>
<style>
.formWidth {
  width: 221.5px;
}
</style>
