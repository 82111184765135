<template>
  <div>
    <el-dialog
      title="添加泊位"
      :visible.sync="dialogVisible"
      width="500"
      :show-close="false"
      :close-on-click-modal="false"
      custom-class="dialog"
    >
      <el-form ref="form" :model="form" label-width="120px" style="padding: 0">
        <el-form-item :label="$t('searchModule.Berth_number')">
          <span></span>
        </el-form-item>
        <el-form-item label="系统编号">
          <span></span>
        </el-form-item>
        <el-form-item label="服务属性">
          <el-checkbox-group v-model="form.name">
            <el-checkbox label="1">潮汐泊位</el-checkbox>
            <el-checkbox label="2">共享泊位</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Berth_type')">
          <el-select v-model="form.name" placeholder="请选择">
            <el-option label="视频泊位" value="1"></el-option>
            <el-option label="PDA泊位" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="充电泊位">
          <el-radio v-model="form.name" label="1">否</el-radio>
          <el-radio v-model="form.name" label="2">是</el-radio>
        </el-form-item>
        <el-form-item label="对应设备号">
          <el-transfer
            target-order="push"
            :titles="['Source', 'Target']"
            filterable
            filter-placeholder="请输入城市拼音"
            v-model="form.name"
            :data="data2"
          >
          </el-transfer>
        </el-form-item>
        <el-form-item label="费率配置">
          <el-transfer
            target-order="push"
            :titles="['Source', 'Target']"
            filterable
            filter-placeholder="请输入城市拼音"
            v-model="form.name"
            :data="data2"
          >
          </el-transfer>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="dialogVisible = false">{{ $t('button.preservation') }}</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {},
      data2: [],
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
  },
};
</script>
<style>
.formWidth {
  width: 221.5px;
}

</style>
