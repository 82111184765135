<template>
  <div>
    <el-dialog
      title="查看杆位"
      :visible.sync="dialogVisible"
      width="500px"
      :show-close="false"
      custom-class="dialog"
    >
      <el-form ref="form" :model="formInline" label-width="120px" :rules="rules" style="padding: 0">
        <el-form-item label="杆位号:" prop="barCode">
          <span>{{ formInline.barCode }}</span>
        </el-form-item>
        <el-form-item label="经纬度:" prop="">
          <span>{{ this.lng }}</span>
        </el-form-item>
        <el-form-item label="是否自有杆:" prop="own">
          <span v-if="formInline.own == 0">是</span>
          <span v-if="formInline.own == 1">否</span>
        </el-form-item>
        <el-form-item label="视频设备:" prop="">
          <div class="listStyleWrapper">
            <div v-for="v in videoList" class="listStyle" :key="v.equimentId">
              {{ v.equipmentName }}
            </div>
          </div>
        </el-form-item>
        <el-form-item label="监控设备:" prop="">
          <div class="listStyleWrapper">
            <div v-for="v in monitor" class="listStyle" :key="v.monitorEquipmentId">
              {{ v.monitorEquipmentName }}
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible = false">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lng: "",
      barId: "",
      selectedVideo: [],
      selectedMonitor: [],
      videoList: [{ equimentId: 123, equipmentName: "无" }],
      monitor: [{ equimentId: 123, equipmentName: "无" }],
      dialogVisible: false,
      data2: [],
      formInline: {
        barCode: "",
        equipmentIds: "",
        monitorEquipmentIds: "",
      },
      rules: {
        barCode: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["videoEquipment", "monitorEquipment"],
  methods: {
    open() {
      this.dialogVisible = true;
      this.videoList = [];
      this.monitor = [];
      this.formInline.barCode = "";
    },
    getDetail({ barId }) {
      this.lng = "";
      this.barId = barId;
      let url = "/acb/2.0/bar/detail";
      this.$axios
        .get(url, {
          data: {
            barId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline.barCode = res.value.barCode;
            this.formInline.own = res.value.own;
            let log = res.value.longitude || "";
            let lat = res.value.latitude || "";
            if (log || lat) {
              this.lng = log / 1000000 + "," + lat / 1000000;
            }
            if (res.value.equipmentVoList.length) {
              this.videoList = res.value.equipmentVoList;
            }
            if (res.value.monitorEquipmentVoList.length) {
              this.monitor = res.value.monitorEquipmentVoList;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    save() {
      this.formInline.equipmentIds = this.selectedVideo.join(",");
      this.formInline.monitorEquipmentIds = this.selectedMonitor.join(",");
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$axios
            .post("/acb/2.0/bar/update", {
              data: {
                barId: this.barId,
                parkId: this.$route.query.parkId,
                ...this.formInline,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$emit("searchData");
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        }
      });
    },
  },
  created() {},
};
</script>
<style>
.formWidth {
  width: 221.5px;
}

.listStyle {
  text-align: center;
  border-bottom: 1px solid rgb(204, 204, 204);
  background: rgb(237, 241, 244);
}
.listStyleWrapper {
  width: 255px;
  height: 250px;
  background: #fefefe;
  border: 1px solid rgb(204, 204, 204);
  overflow: auto;
}
</style>
