<template>
  <div class="berthLineSetting">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <el-form
          :inline="true"
          label-position="right"
          label-width="100px"
          :model="formInline"
          class="demo-form-inline"
        >
          <el-form-item :label="$t('searchModule.Equipment_name')">
            <el-select
              v-model.trim="formInline.equipmentId"
              @change="getImg"
              size="15"
              filterable
              placeholder="选择设备"
              style="width: 250px"
            >
              <!-- <el-option label="全部" value=""></el-option> -->
              <el-option
                v-for="equipment in equipmentList"
                :key="equipment.equipmentId"
                :label="equipment.equipmentName"
                :value="equipment.equipmentId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="泊位数">
            <p id="berthCountP">{{ berthNum }}</p>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getImg">{{ $t('button.search') }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="divImgShow">
      <div class="content">
        <div style="float: left">
          <ul class="berth-button-wrapper">
            <li class="berth-button" :class="{ dis: but1 }" @click="addBerth(1)">
              <div>1</div>
            </li>
            <li class="berth-button" :class="{ dis: but2 }" @click="addBerth(2)">
              <div>2</div>
            </li>
            <li class="berth-button" :class="{ dis: but3 }" @click="addBerth(3)">
              <div>3</div>
            </li>
            <li class="berth-button" :class="{ dis: but4 }" @click="addBerth(4)">
              <div>4</div>
            </li>
            <li class="berth-button" :class="{ dis: but5 }" @click="addBerth(5)">
              <div>5</div>
            </li>
            <li class="berth-button" :class="{ dis: but6 }" @click="addBerth(6)">
              <div>6</div>
            </li>
            <li class="berth-button" :class="{ dis: but7 }" @click="addBerth(7)">
              <div>7</div>
            </li>
            <li class="berth-button" :class="{ dis: but8 }" @click="addBerth(8)">
              <div>8</div>
            </li>
          </ul>
          <div
            class="delete-rect"
            @click="deleteBerth"
            :class="{ dis: deleteFlag }"
            style="cursor: pointer"
          >
            <i class="el-icon-delete" style="font-size: 23px; margin-right: 5px"></i>删除
          </div>
        </div>
        <div class="cavs-wrapper">
          <div
            v-show="formInline.equipmentId"
            onclick="javascript:;"
            style="width: 540px; height: 960px"
          >
            <img
              :src="imgUrl"
              v-show="imgUrl"
              onclick="javascript:;"
              style="width: 100%; height: 100%"
            />
            <canvas
              v-show="imgUrl"
              width="540"
              height="960"
              ref="cavs"
              style="position: absolute; left: 0; top: 0"
            >
              你的浏览器还不支持canvas
            </canvas>
            <el-upload
              style="margin: 100px auto; width: 360px"
              v-show="!imgUrl"
              name="image"
              class="upload-berth-pic"
              action="/acb/2.0/berthCoordinate/upload"
              ref="uploadRef"
              :data="uploadData"
              :headers="headers"
              :on-success="saveImgSuccess"
              :on-change="berthImgChange"
              :auto-upload="false"
              :show-file-list="false"
            >
              <el-button
                style="text-align: center; width: 360px; height: 200px"
                class="el-upload__text"
                @click="picChange('uploadRef')"
              >
                <i class="el-icon-upload" style="font-size: 50px"></i>
                <br />
                点击上传
              </el-button>
              <div class="el-upload__tip" slot="tip" style="margin-left: 70px">
                只能上传jpg/png文件，且不超过500kb
              </div>
            </el-upload>
          </div>
          <div v-show="!formInline.equipmentId">
            <div class="warningImg">
              <i class="el-icon-warning"></i>
              <span>请先选择要设置的设备</span>
            </div>
          </div>
        </div>
        <div style="float: left">
          <el-button class="saveBtn" type="primary" :disabled="isAdding" @click="saveBerthLine"
            >{{ $t('button.preservation') }}</el-button
          >
          <el-upload
            class="upload-Btn"
            action="/acb/2.0/berthCoordinate/upload"
            :show-file-list="false"
            :on-success="saveImgSuccess"
            :headers="headers"
            name="image"
            :auto-upload="false"
            :data="uploadData"
            ref="picChange"
            :on-change="berthImgChange"
          >
            <el-button
              class="saveBtn"
              style="margin-top: 10px"
              @click="picChange('picChange')"
              type="primary"
              >更换图片</el-button
            >
          </el-upload>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//  import cavasComp from './cavas'
import //  getDt
"@/common/js/public.js";
export default {
  name: "hello",
  data() {
    return {
      isAdding: false,
      warningShow: true,
      index: 0,
      berthCoordinateId: "",
      curPicRef: "",
      berthNum: "",
      equipmentList: [],
      imgUrl: "",
      offsetX: 200,
      offsetY: 30,
      wScale: "1",
      hScale: "1",
      but1: false,
      but2: false,
      but3: false,
      but4: false,
      but5: false,
      but6: false,
      but7: false,
      but8: false,
      deleteFlag: false,
      uploadData: {},
      upData: {
        "Session-Id": sessionStorage.token,
      },
      headers: {
        "Session-Id": sessionStorage.token,
      },
      formInline: {
        berthLineImage: "",
        berthIdList: "",
        coordinateList: "",
        equipmentId: "",
      },
    };
  },
  methods: {
    picChange(str) {
      this.curPicRef = str;
    },
    // 根据车场获取设备
    getEquipment(equipmentId) {
      this.$axios
        .get("/acb/2.0/berthCoordinate/equipment/byParkId", {
          data: {
            parkId: this.$route.query.parkId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.equipmentList = res.value.list;
            if (this.equipmentList.length) {
              if (equipmentId) {
                this.formInline.equipmentId = equipmentId;
              } else {
                this.formInline.equipmentId = this.equipmentList[0].equipmentId;
              }
              this.getImg();
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 获取泊位划线
    getBerthLine() {
      let url = "/acb/2.0/berthCoordinate/getByEquipmentId";
      this.$axios
        .get(url, {
          data: {
            equipmentId: this.formInline.equipmentId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.value && res.value.coordinate && res.value.coordinate.length > 0) {
              this.berthCoordinateId = res.value.berthCoordinateId;
              this.oCavs.rePrint(JSON.parse(res.value.coordinate));
            } else {
              this.oCavs.clearCavs();
            }
            this.setClass();
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleBerthList(data) {
      let col = [];
      data.forEach((value) => {
        col.push({
          berthId: value.berthId,
          berthCode: value.berthCode,
        });
      });
      return col;
    },
    getImg() {
      if (!this.formInline.equipmentId) {
        this.$alert("请选择设备！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      let url = "/acb/2.0/berthCoordinate/queryImage";
      this.$axios
        .get(url, {
          data: {
            equipmentId: this.formInline.equipmentId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline.berthLineImage = res.value;
            this.imgUrl = res.value;
            this.setClass();
            // 获取到图片 获取点
            this.getBerthList();
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    initPage() {
      this.formInline.equipmentId = "";
      this.imgUrl = "";
      this.formInline.berthLineImage = "";
      this.berthNum = "";
      this.curPicRef = "";
      this.setClass();
    },
    // 获取泊位列表
    getBerthList() {
      if (this.formInline.equipmentId == "") {
        this.initPage();
        return;
      }
      let url = "/acb/2.0/berthCoordinate/berth/byEquipmentId";
      this.$axios
        .get(url, {
          data: {
            equipmentId: this.formInline.equipmentId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            let berthList = this.handleBerthList(res.value);
            this.oCavs.berthList = berthList;
            this.oCavs.berthNum = berthList.length;
            this.berthNum = berthList.length;
            this.getBerthLine();
            this.formInline.berthIdList = JSON.stringify(berthList);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    berthImgChange(res) {
      this.imgUrl = res.url;
      if (!this.imgUrl) this.imgUrl = URL.createObjectURL(res.raw);
      this.setClass();
    },
    saveImgSuccess(res, file) {
      if (res.state == 0) {
        // 上传图片成功再发送点数据
        this.formInline.berthLineImage = res.value;
        this.sendData();
      } else {
        this.$message.error("图片上传失败");
      }
    },
    // 先上传图片
    saveBerthLine() {
      if (this.oCavs.getDrawedBerthNum() != this.berthNum) {
        this.$alert("当前绘制的泊位数与实际泊位数不一致！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (this.formInline.berthLineImage && !this.curPicRef) {
        this.sendData();
        return;
      }
      if (!this.curPicRef) {
        this.$alert("请先选择图片", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.uploadData.equipmentId = this.formInline.equipmentId;
      this.$refs[this.curPicRef].submit();
    },
    setClass() {
      let curNum = this.oCavs.getDrawedBerthNum();
      let n = this.berthNum - curNum;
      if (!this.imgUrl) {
        n = 0;
      }
      for (let i = 1; i <= n; i++) {
        this["but" + i] = false;
      }
      for (let i = n + 1; i <= 8; i++) {
        this["but" + i] = true;
      }
      // 当没有图形的时候 不能点击删除
      if (this.berthNum == 0 || curNum == 0) {
        this.deleteFlag = true;
      } else {
        this.deleteFlag = false;
      }
    },
    initCavas() {
      let that = this;
      class PrintPic {
        constructor(opt) {
          this.currentIndex = null;
          this.i = 0;
          this.errNum = 12;
          this.cavsId = opt.cavsId; // 预览图的画布
          this.berthNum = opt.berthNum; //  最终的泊位数
          this.currentBerthNum = 1;
          this.currentTable = [];
          this.berthList = opt.berthList;
          this.offsetX = opt.offsetX || 0;
          this.offsetY = opt.offsetY || 0;
          this.wScale = opt.wScale;
          this.hScale = opt.hScale;
          this.myPointArr = [
            { x: 0 + this.offsetX, y: 0 + this.offsetY },
            { x: 0 + this.offsetX, y: 600 + this.offsetY },
            { x: 150 + this.offsetX, y: 600 + this.offsetY },
            { x: 150 + this.offsetX, y: 0 + this.offsetY },
          ];
          this.initCavs();
          this.bindEvent();
        }
        initCavs() {
          // this.cavs = document.getElementById(this.cavsId);
          this.cavs = that.$refs.cavs;
          this.context = this.cavs.getContext("2d");
          this.context.lineWidth = "5";
        }
        dataOrder(data) {
          let result;
          let arr = data.slice(0);
          result = arr.sort((cur, next) => {
            return cur.position[0][1] - next.position[0][1];
          });
          return result;
        }
        //  重汇
        rePrint(tableData) {
          let data = tableData.slice(0);
          //  data.reverse();
          this.currentTable = this.handleGetingData(this.dataOrder(data));
          this.currentIndex = this.currentTable.length - 1;
          this.context.clearRect(0, 0, this.cavs.width, this.cavs.height);
          //   this.calAllPoints();
          this.drawAllTable();
        }
        //  校验外面四个点坐标
        checkFourPoints(myPointArr) {
          let len = myPointArr.length;
          let errNum = this.errNum;
          if (len < 6) {
            if (myPointArr[1].y - myPointArr[0].y < errNum) {
              if (this.clickIndex == 1) {
                myPointArr[0].y = myPointArr[1].y - errNum;
              }
              if (this.clickIndex == 0) {
                myPointArr[1].y = myPointArr[0].y + errNum;
              }
              return false;
            } else if (myPointArr[2].y - myPointArr[3].y < errNum) {
              if (this.clickIndex == 2) {
                myPointArr[3].y = myPointArr[2].y - errNum;
              }
              if (this.clickIndex == 3) {
                myPointArr[2].y = myPointArr[3].y + errNum;
              }
              return false;
            } else {
              return true;
            }
          } else {
            if (myPointArr[4].y - myPointArr[0].y < errNum) {
              if (this.clickIndex == 0) {
                myPointArr[4].y = myPointArr[0].y + errNum;
              }
              return false;
            } else if (myPointArr[5].y - myPointArr[3].y < errNum) {
              if (this.clickIndex == 3) {
                myPointArr[5].y = myPointArr[3].y + errNum;
              }
              return false;
            }
            if (myPointArr[1].y - myPointArr[len - 2].y < errNum) {
              if (this.clickIndex == 1) {
                myPointArr[len - 2].y = myPointArr[1].y - errNum;
              }
              return false;
            } else if (myPointArr[2].y - myPointArr[len - 1].y < errNum) {
              if (this.clickIndex == 2) {
                myPointArr[len - 1].y = myPointArr[2].y - errNum;
              }
              return false;
            }
            return true;
          }
        }
        //  绘制每一个图形
        drawReact(myPointArr) {
          this.drawLine(myPointArr[0], myPointArr[1]);
          this.drawLine(myPointArr[1], myPointArr[2]);
          this.drawLine(myPointArr[2], myPointArr[3]);
          this.drawLine(myPointArr[3], myPointArr[0]);
          let addPonintsNum = myPointArr.length - 4;
          for (let i = 0; i < addPonintsNum / 2; i++) {
            this.drawLine(myPointArr[4 + i * 2], myPointArr[5 + i * 2]);
          }
        }
        setPoints(myPointArr) {
          let pHeight = 600;
          let r = Math.round(pHeight / this.currentBerthNum); //  点在竖直方向的距离
          let addPonintsNum = (this.currentBerthNum - 1) * 2; //  增加的点的个数
          for (let i = 0; i < addPonintsNum / 2; i++) {
            myPointArr.push({ x: 0 + this.offsetX, y: (i + 1) * r + 0 + this.offsetY });
            myPointArr.push({ x: 150 + this.offsetX, y: (i + 1) * r + 0 + this.offsetY });
          }
        }
        drawLine(beginPoint, stopPoint) {
          let context = this.context;
          context.beginPath();
          context.moveTo(beginPoint.x, beginPoint.y);
          context.lineTo(stopPoint.x, stopPoint.y);
          context.closePath();
          context.stroke();
        }
        //  计算其余点的坐标
        calPonints(startPoint, endPoint, data, value, index) {
          let targetY = data.y;
          let result;
          let targetX;
          let k;
          let b;
          if (endPoint.x - startPoint.x != 0) {
            k = (endPoint.y - startPoint.y) / (endPoint.x - startPoint.x);
            b = startPoint.y - k * startPoint.x;
            targetX = Math.round((targetY - b) / k);
          } else {
            targetX = startPoint.x;
          }
          result = { x: targetX, y: targetY };
          // this.checkTwoPoints(result, value, index)
          return result;
        }
        //  绘制所有图形
        drawAllTable() {
          this.currentTable.forEach((value, index) => {
            if (this.currentIndex == index) {
              this.context.strokeStyle = "#F5270B";
            } else {
              this.context.strokeStyle = "#000";
            }
            this.drawReact(value);
          });
        }
        //  计算点击图形的所有点坐标
        calAllPoints() {
          this.currentTable.forEach((value, index) => {
            let addPonintsNum = value.length - 4;
            for (let i = 0; i < addPonintsNum / 2; i++) {
              value[4 + i * 2] = this.calPonints(
                value[0],
                value[1],
                value[4 + i * 2],
                value,
                4 + i * 2
              ); //  左边的点
              value[5 + i * 2] = this.calPonints(
                value[2],
                value[3],
                value[5 + i * 2],
                value,
                5 + i * 2
              ); //  右边的点
            }
          });
        }
        // lais
        checkAllpoints() {
          this.currentTable.forEach((value, index) => {
            let addPonintsNum = value.length - 4;
            if (index != this.currentIndex) {
              return;
            }
            if (this.direction < 0) {
              for (let i = addPonintsNum / 2 - 1; i >= 0; i--) {
                this.checkTwoPoints(value[4 + i * 2], value, 4 + i * 2);
                this.checkTwoPoints(value[5 + i * 2], value, 5 + i * 2);
              }
            } else {
              for (let i = 0; i < addPonintsNum / 2; i++) {
                this.checkTwoPoints(value[4 + i * 2], value, 4 + i * 2);
                this.checkTwoPoints(value[5 + i * 2], value, 5 + i * 2);
              }
            }
          });
        }
        checkTwoPoints(result, value, index) {
          let errNum = this.errNum;
          let leftIndex = value.length - 2; // 左下角的点
          let rightIndex = value.length - 1; // 右下角的点
          if (index < 4) {
          } else {
            if (value.length > 6) {
              // 当前点击的左下角的点9
              if (index == leftIndex) {
                if (result.y - value[leftIndex - 2].y < errNum) {
                  value[leftIndex - 2].y = result.y - errNum;
                  this.flagIndex = index;
                } else if (value[1].y - result.y < errNum) {
                  value[1].y = result.y + errNum;
                } else {
                }
                // 当前点击的右下角的点
              } else if (index == rightIndex) {
                if (result.y - value[rightIndex - 2].y < errNum) {
                  value[rightIndex - 2].y = result.y - errNum;
                } else if (value[2].y - result.y < errNum) {
                  value[2].y = result.y + errNum;
                } else {
                }
              } else {
                if (index == 4 || index == 5) {
                  if (value[index + 2]) {
                    //  下面的点存在的情况
                    if (value[index + 2].y - result.y < errNum) {
                      value[index + 2].y = result.y + errNum;
                    } else if (index == 5) {
                      if (result.y - value[3].y < errNum) {
                        value[3].y = result.y - errNum;
                      } else {
                      }
                    } else if (index == 4) {
                      if (result.y - value[0].y < errNum) {
                        value[0].y = result.y - errNum;
                      } else {
                      }
                    }
                  } else {
                    //  下面的点不存在的情况
                    if (index == 5) {
                      if (result.y - value[3].y < errNum) {
                        value[3].y = result.y - errNum;
                      } else if (value[2].y - result.y < errNum) {
                        value[2].y = result.y + errNum;
                      } else {
                      }
                    } else if (index == 4) {
                      if (result.y - value[0].y < errNum) {
                        value[0].y = result.y - errNum;
                      } else if (value[1].y - result.y < errNum) {
                        value[1].y = result.y + errNum;
                      } else {
                      }
                    }
                    //   return true
                  }
                } else {
                  if (result.y - value[index - 2].y < errNum) {
                    value[index - 2].y = result.y - errNum;
                  } else if (value[index + 2].y - result.y < errNum) {
                    value[index + 2].y = result.y + errNum;
                  } else {
                  }
                }
              }
            } else {
              if (index == 4 || index == 5) {
                if (value[index + 2]) {
                  //  下面的点存在的情况
                  if (value[index + 2].y - result.y < errNum) {
                  } else if (index == 5) {
                    if (result.y - value[3].y < errNum) {
                    } else {
                    }
                  } else if (index == 4) {
                    if (result.y - value[0].y < errNum) {
                    } else {
                    }
                  }
                } else {
                  //  下面的点不存在的情况
                  if (index == 5) {
                    if (result.y - value[3].y < errNum) {
                      value[3].y = result.y - errNum;
                    } else if (value[2].y - result.y < errNum) {
                      value[2].y = result.y + errNum;
                    } else {
                    }
                  } else if (index == 4) {
                    if (result.y - value[0].y < errNum) {
                      value[0].y = result.y - errNum;
                    } else if (value[1].y - result.y < errNum) {
                      value[1].y = result.y + errNum;
                    } else {
                    }
                  }
                  //   return true
                }
              }
            }
          }
        }
        deleteData() {
          if (this.currentIndex >= 0) {
            this.currentTable.splice(this.currentIndex, 1);
            this.currentIndex = this.currentTable.length - 1;
            this.context.clearRect(0, 0, this.cavs.width, this.cavs.height);
            this.drawAllTable();
          }
        }
        clearCavs() {
          this.currentTable = [];
          this.context.clearRect(0, 0, this.cavs.width, this.cavs.height);
        }
        bindEvent() {
          this.cavs.onmousedown = (e) => {
            e.stopPropagation();
            let x = e.offsetX;
            let y = e.offsetY;
            let currentIndex;
            let flag = false;
            let clickFlag = false;
            //  let len = this.currentTable.length - 1;
            let currentPoints;
            //  判断点击的图形是哪个
            for (let j in this.currentTable) {
              this.currentTable[j].forEach((value, index) => {
                if (x > value.x - 10 && x < value.x + 10) {
                  if (y > value.y - 10 && y < value.y + 10) {
                    clickFlag = true;
                    currentIndex = index;
                    this.currentIndex = j;
                    currentPoints = this.currentTable[j];
                  }
                }
              });
              //  如果找到对应图形停止循环
              if (clickFlag) {
                this.drawAllTable();
                //   break;
              }
            }
            if (!clickFlag) {
              return;
            }
            currentPoints.forEach((value, index) => {
              if (x > value.x - 10 && x < value.x + 10) {
                if (y > value.y - 10 && y < value.y + 10) {
                  flag = true;
                  currentIndex = index;
                  this.clickIndex = index;
                }
              }
            });
            if (flag) {
              flag = false;
              this.cavs.onmousemove = (e) => {
                this.oldData = Object.assign(currentPoints);
                this.oldY = currentPoints[currentIndex].y;
                currentPoints[currentIndex].x = e.offsetX;
                currentPoints[currentIndex].y = e.offsetY;
                if (e.offsetY - this.oldY < 0) {
                  this.direction = -1;
                }
                if (e.offsetY - this.oldY >= 0) {
                  this.direction = 1;
                }
                this.checkFourPoints(currentPoints);
                this.context.clearRect(0, 0, this.cavs.width, this.cavs.height);
                this.checkAllpoints();
                this.calAllPoints();
                this.drawAllTable();
              };
              document.onmouseup = (e) => {
                this.cavs.onmousemove = null;
                document.onmouseup = null;
              };
            }
          };
        }
        //  click事件
        handleClick(berthNum) {
          this.context.strokeStyle = "#F5270B";
          this.currentBerthNum = berthNum;

          let myPointArr = [
            { x: 0 + this.offsetX, y: 0 + this.offsetY },
            { x: 0 + this.offsetX, y: 600 + this.offsetY },
            { x: 150 + this.offsetX, y: 600 + this.offsetY },
            { x: 150 + this.offsetX, y: 0 + this.offsetY },
          ];
          //  设置多余的点
          this.setPoints(myPointArr);
          //  把当前点放到所有点数组
          this.currentTable.push(myPointArr);
          this.currentIndex = this.currentTable.length - 1;
          this.context.clearRect(0, 0, this.cavs.width, this.cavs.height);
          this.drawAllTable();
        }
        getDrawedBerthNum() {
          let num = 0;
          this.currentTable.forEach((value) => {
            if (value.length < 6) {
              num++;
            } else {
              let rNum = (value.length - 4) / 2 + 1;
              num += rNum;
            }
          });
          return num;
        }
        //  处理需要发送的数据
        handleData() {
          let result;
          result = this.currentTable.sort((cur, next) => {
            return cur[2].y - next[2].y;
          });
          let finalData = [];
          //  现将图形的数据处理成小方块的点 四个点一个方块
          result.forEach((value, index) => {
            let col = [];
            let len = value.length;
            let num = (value.length - 4) / 2 + 1;
            for (let i = 1; i <= num; i++) {
              if (num == 1) {
                col.push([
                  Math.round(value[0].x * this.wScale),
                  Math.round(value[0].y * this.hScale),
                ]);
                col.push([
                  Math.round(value[3].x * this.wScale),
                  Math.round(value[3].y * this.hScale),
                ]);
                col.push([
                  Math.round(value[1].x * this.wScale),
                  Math.round(value[1].y * this.hScale),
                ]);
                col.push([
                  Math.round(value[2].x * this.wScale),
                  Math.round(value[2].y * this.hScale),
                ]);
              } else {
                if (i == 1) {
                  col.push([
                    Math.round(value[0].x * this.wScale),
                    Math.round(value[0].y * this.hScale),
                  ]);
                  col.push([
                    Math.round(value[3].x * this.wScale),
                    Math.round(value[3].y * this.hScale),
                  ]);
                  col.push([
                    Math.round(value[4].x * this.wScale),
                    Math.round(value[4].y * this.hScale),
                  ]);
                  col.push([
                    Math.round(value[5].x * this.wScale),
                    Math.round(value[5].y * this.hScale),
                  ]);
                } else if (i == num) {
                  col.push([
                    Math.round(value[len - 2].x * this.wScale),
                    Math.round(value[len - 2].y * this.hScale),
                  ]);
                  col.push([
                    Math.round(value[len - 1].x * this.wScale),
                    Math.round(value[len - 1].y * this.hScale),
                  ]);
                  col.push([
                    Math.round(value[1].x * this.wScale),
                    Math.round(value[1].y * this.hScale),
                  ]);
                  col.push([
                    Math.round(value[2].x * this.wScale),
                    Math.round(value[2].y * this.hScale),
                  ]);
                } else {
                  col.push([
                    Math.round(value[3 + (i - 2) * 2 + 1].x * this.wScale),
                    Math.round(value[3 + (i - 2) * 2 + 1].y * this.hScale),
                  ]);
                  col.push([
                    Math.round(value[3 + (i - 2) * 2 + 2].x * this.wScale),
                    Math.round(value[3 + (i - 2) * 2 + 2].y * this.hScale),
                  ]);
                  col.push([
                    Math.round(value[3 + (i - 2) * 2 + 3].x * this.wScale),
                    Math.round(value[3 + (i - 2) * 2 + 3].y * this.hScale),
                  ]);
                  col.push([
                    Math.round(value[3 + (i - 2) * 2 + 4].x * this.wScale),
                    Math.round(value[3 + (i - 2) * 2 + 4].y * this.hScale),
                  ]);
                }
              }
            }
            finalData.push(col);
          });
          let sendData = [];
          finalData.forEach((value) => {
            let len = value.length / 4;
            for (let i = 1; i <= len; i++) {
              sendData.push({
                //   berthId: this.berthList(berthIndex++),
                position: [value[i * 4 - 4], value[i * 4 - 3], value[i * 4 - 2], value[i * 4 - 1]],
              });
            }
          });
          sendData.reverse();
          // 除了点数据之外的数据
          sendData.forEach((value, index) => {
            value.berthId = this.berthList[index].berthId;
            value.berthCode = this.berthList[index].berthCode;
          });
          sessionStorage.tableData = JSON.stringify(sendData);
          return sendData;
          //     this.handleGetingData(sendData);
        }
        //  把获取的数据处理成可以绘图的数据
        handleGetingData(data) {
          let len = data.length;
          let rNum = 0; //  用于保存图形的个数
          let result = [];
          result.push([]);
          for (let i = 0; i < len; i++) {
            result[rNum].push(data[i].position);
            if (data[i + 1]) {
              //    判断是否有断口 必须用或 避免有重合的点
              if (
                data[i].position[2][0] != data[i + 1].position[0][0] ||
                data[i].position[2][1] != data[i + 1].position[0][1]
              ) {
                if (
                  data[i].position[3][0] != data[i + 1].position[1][0] ||
                  data[i].position[3][1] != data[i + 1].position[1][1]
                ) {
                  rNum += 1;
                  result.push([]);
                }
              }
            }
          }
          let printData = [];
          printData.push([]);
          let pNum = 0;
          result.forEach((value) => {
            let num = value.length; //  一个图形中方块的个数
            printData[pNum].push(
              {
                x: Math.round(value[0][0][0] / this.wScale),
                y: Math.round(value[0][0][1] / this.hScale),
              },
              {
                x: Math.round(value[num - 1][2][0] / this.wScale),
                y: Math.round(value[num - 1][2][1] / this.hScale),
              },
              {
                x: Math.round(value[num - 1][3][0] / this.wScale),
                y: Math.round(value[num - 1][3][1] / this.hScale),
              },
              {
                x: Math.round(value[0][1][0] / this.wScale),
                y: Math.round(value[0][1][1] / this.hScale),
              }
            );
            //   当点个数大于四个 将其他点放到数组中
            if (num > 1) {
              for (let j = 0; j < num - 1; j++) {
                printData[pNum].push(
                  {
                    x: Math.round(value[j][2][0] / this.wScale),
                    y: Math.round(value[j][2][1] / this.hScale),
                  },
                  {
                    x: Math.round(value[j][3][0] / this.wScale),
                    y: Math.round(value[j][3][1] / this.hScale),
                  }
                );
              }
            }
            if (pNum == result.length - 1) {
            } else {
              printData.push([]);
            }
            pNum++;
          });
          this.printData = printData;
          // console.log('printData', printData)
          return printData;
        }
      }
      var opt = {
        cavsId: "cavs", //
        berthList: [1, 2],
        offsetX: 200,
        offsetY: 60,
        wScale: 2,
        hScale: 2,
        berthNum: 4,
      };
      var oCavs = new PrintPic(opt);
      this.oCavs = oCavs;
    },
    addBerth(num) {
      if (this.file || this.imgUrl) {
        if (!this["but" + num]) {
          this.oCavs.handleClick(num);
          this.setClass();
        }
      } else {
      }
    },
    deleteBerth() {
      this.oCavs.deleteData();
      this.setClass();
    },
    sendData() {
      let handledData = this.oCavs.handleData();
      if (handledData) {
        this.formInline.coordinateList = JSON.stringify(handledData);
      } else {
        return;
      }
      //   if (!this.formInline.coordinateList) {
      //       this.$alert('请绘制泊位线', this.$t('pop_up.Tips'), {
      //                 confirmButtonText: this.$t('pop_up.Determine')
      //             });
      //       return;
      //   }
      let { coordinateList } = this.formInline;
      let data = {};
      if (!this.berthCoordinateId) {
        data = {
          equipmentId: this.formInline.equipmentId,
          coordinate: coordinateList,
        };
      } else {
        data = {
          berthCoordinateId: this.berthCoordinateId,
          equipmentId: this.formInline.equipmentId,
          coordinate: coordinateList,
        };
      }
      this.isAdding = true;
      this.$axios
        .post("/acb/2.0/berthCoordinate/add", {
          data: data,
        })
        .then((res) => {
          this.isAdding = false;
          if (res.state == 0) {
            this.$alert("操作成功", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              let equipmentId = this.formInline.equipmentId;
              this.initPage();
              this.getEquipment(equipmentId);
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  components: {},
  created() {},
  mounted() {
    this.initCavas();
    this.setClass();
  },
  computed: {},
};
</script>

<style scoped>
.updateImg {
  margin: 0 auto;
}

.upload-Drag {
  width: 30%;
  /*padding-top: 40px;*/
  margin: 60px auto 0 auto;
}

.el-upload__tip {
  margin: 17px auto 0 auto;
}

.warningImg {
  width: 180px;
  margin: 200px auto 0 auto;
}

.warningImg span {
  margin: 0 auto;
}

.saveBtn {
  margin-left: 2px;
  width: 72px;
  height: 30px;
  line-height: 30px;
  padding: 0;
  display: block;
}

.divImgShow {
  background: #5f6379;
}
.divImgShow .content {
  width: 760px;
  min-width: 676px;
  margin: auto;
  background: #5f6379;
}

.backGround {
  width: 420px;
  height: 673px;
  border: 1px solid black;
  margin: 40px auto 0 auto;
}
.berth-button-wrapper {
  width: 124px;
  overflow: hidden;
}
.berth-button {
  float: left;
  width: 62px;
  height: 62px;
  box-sizing: border-box;
  background: rgba(153, 169, 191, 1);
  padding: 9px 9px;
  cursor: pointer;
  border-right: 2px solid #5f6379;
  border-bottom: 2px solid#5F6379;
}
.berth-button > div {
  width: 44px;
  height: 44px;
  font-size: 20px;
  line-height: 44px;
  text-align: center;
  background: url("berth_button.png");
}
.berth-button:nth-of-type(2) > div {
  background-position: -44px 0;
}
.berth-button:nth-of-type(3) > div {
  background-position: -88px 0;
}
.berth-button:nth-of-type(4) > div {
  background-position: -132px 0;
}
.berth-button:nth-of-type(5) > div {
  background-position: -176px 0;
}
.berth-button:nth-of-type(6) > div {
  background-position: -220px 0;
}
.berth-button:nth-of-type(7) > div {
  background-position: -264px 0;
}
.berth-button:nth-of-type(8) > div {
  background-position: -308px 0;
}
#berthCountP {
  width: 100px;
  height: 40px;
  /*display: inline-block;*/
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #c4c4c4;
  color: #1f2d3d;
  margin: 0;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
  text-align: center;
}
.delete-rect {
  width: 122px;
  height: 60px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: rgba(248, 248, 248, 1);
  line-height: 60px;
  text-align: center;
  background: rgba(214, 77, 77, 1);
}
.cavs-wrapper {
  float: left;
  width: 540px;
  height: 960px;
  position: relative;
  background: #fff;
}
.dis {
  opacity: 0.5;
  cursor: not-allowed !important;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 30px
  overflow hidden
.content
  background #FFFFFF
  border-radius 3px
  padding 15px
  overflow hidden
  .searchWrapper
    overflow hidden
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
</style>
