<template>
  <div>
    <el-dialog
      title="收费员配置"
      :visible.sync="dialogVisible"
      width="500"
      :close-on-click-modal="false"
      :show-close="false"
      custom-class="dialog"
    >
      <el-form ref="form" :model="form" label-width="120px" style="padding: 0">
        <el-form-item :label="$t('searchModule.Toll_collector')">
          <el-transfer
            target-order="push"
            ref="transfer"
            :titles="['未选择', '已选择']"
            filterable
            filter-placeholder="请输入..."
            v-model="pdaManagerIds"
            :props="{
              label: 'pdaManagerName',
              key: 'pdaManagerId',
            }"
            :data="data2"
          >
          </el-transfer>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="save">{{ $t('button.preservation') }}</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      pdaManagerIds: [],
      form: {
        pdaManagerIds: "",
      },
      data2: [],
    };
  },
  methods: {
    open() {
      this.$refs.transfer && this.$refs.transfer.clearQuery("left");
      this.$refs.transfer && this.$refs.transfer.clearQuery("right");
      this.pdaManagerIds = [];
      this.dialogVisible = true;
      this.searchData();
    },
    save() {
      if (this.pdaManagerIds.length == 0) {
        this.$alert("请至少选择一个收费员！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.form.pdaManagerIds = this.pdaManagerIds.join(",");
      this.$axios
        .post("/acb/2.0/pdaManagerPark/create/" + this.$route.query.parkId, {
          data: this.form,
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            if (res.value) {
              this.data2 = res.value.list;
              this.$emit("searchData");
              this.dialogVisible = false;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      this.$axios
        .get("/acb/2.0/pdaManager/queryNoInCurrentPark/" + this.$route.query.parkId, {
          data: {
            page: 0,
            pageSize: 0,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            if (res.value) {
              this.data2 = res.value;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  created() {
    this.searchData();
  },
};
</script>
<style>
.formWidth {
  width: 221.5px;
}

</style>
