<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          ref="form"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Equipment_name')" prop="equipmentName">
                <el-input
                  v-model="formInline.equipmentName"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Equipment_number')" prop="equipmentCode">
                <el-input
                  v-model="formInline.equipmentCode"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item label="对应杆位号" prop="barCode">
                <el-input
                  v-model="formInline.barCode"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.state')" prop="equipmentState">
                <el-select
                  v-model.trim="formInline.equipmentState"
                  filterable
                  placeholder="请选择"
                >
                  <el-option label="上线" value="1"></el-option>
                  <el-option label="下线" value="2"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                type="primary"
                icon="el-icon-plus"
                v-if="authority.button.add"
                @click="addBerth"
                >{{ $t('button.addto') }}</el-button
              >
              <el-button
                type="info"
                plain
                icon="el-icon-upload"
                v-if="authority.button.batchImport"
                @click="openMuli"
                >批量{{ $t('button.import') }}</el-button
              >
              <el-button
                type="info"
                v-if="authority.button.upLine"
                plain
                @click="multiUpdataState(1)"
                >上线</el-button
              >
              <el-button
                type="info"
                v-if="authority.button.downLine"
                plain
                @click="multiUpdataState(2)"
                >下线</el-button
              >
              <el-button
                type="info"
                v-if="authority.button.delete"
                @click="multiDelete"
                >删除</el-button
              >
            </div>
            <div class="col_right">
              <el-button
                type="info"
                plain
                icon="el-icon-upload2"
                @click="exportFile"
                v-if="authority.button.export"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          @selection-change="handleSelectionChange"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <!-- <el-table-column type="index" :label="$t('list.index')" width="70" align='center'></el-table-column> -->
          <el-table-column type="selection" width="55"></el-table-column>
          <!-- <el-table-column type="index" :label="$t('list.index')" width="70" align='center'></el-table-column> -->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="80"
            v-if="
              authority.button.view ||
              authority.button.edit ||
              authority.button.upLine ||
              authority.button.downLine ||
              authority.button.delete
            "
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a" v-if="authority.button.view"
                    >查看</el-dropdown-item
                  >
                  <el-dropdown-item command="b" v-if="authority.button.edit"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="c"
                    v-if="authority.button.upLine"
                    v-show="scope.row.equipmentState == 2"
                    >上线</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="c"
                    v-if="authority.button.downLine"
                    v-show="scope.row.equipmentState == 1"
                    >下线</el-dropdown-item
                  >
                  <el-dropdown-item command="d" v-if="authority.button.delete"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <add-berth
          ref="addBerth"
          @searchData="searchData"
          :brandList="brandList"
          :equipmentType="equipmentTypeList"
          :traceTag="traceTag"
        ></add-berth>
        <edit-berth
          ref="editBerth"
          @searchData="searchData"
          :brandList="brandList"
          :equipmentType="equipmentTypeList"
          :traceTag="traceTag"
        ></edit-berth>
        <berth-detail ref="berthDetail"></berth-detail>
      </div>
      <el-dialog
        title="泊位划线"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="900px"
      >
        <berth-linesetting ref="berthline"></berth-linesetting>
      </el-dialog>
      <upload-file
        ref="upload"
        @success="success"
        :uploadData="uploadData"
        :uploadUrl="uploadUrl"
        :modelUrl="modelUrl"
      ></upload-file>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  exportExcelNew,
  // getDt,
} from "@/common/js/public";
import uploadFile from "@/components/uploadFile/";
import addBerth from "./add";
import editBerth from "./edit";
import berthDetail from "./Detail";
import berthLinesetting from "./berthLineSetting";

export default {
  name: "hello",
  props: ["authority"],
  data() {
    return {
      uploadUrl: "/acb/2.0/equipment/import",
      modelUrl: "/acb/2.0/equipment/downloadExcelTemplate",
      uploadData: {
        parkId: this.$route.query.parkId,
      },
      dialogVisible: false,
      hasSelectedList: [],
      equipmentType: [],
      equipmentTypeList: [],
      parkEquipmentType: "",
      traceTag: [],
      brandList: [],
      pName: "",
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      tableCols: [
        {
          prop: "equipmentName",
          // label: this.$t("list.Equipment_name"),
          label: this.$t("list.Equipment_name"),
          width: "",
        },
        {
          prop: "equipmentCode",
          label: this.$t("list.Equipment_number"),
          width: "",
        },
        {
          prop: "equipmentTypeName",
          label: this.$t("list.Equipment_type"),
          width: "",
        },
        {
          prop: "barCode",
          label: this.$t("list.Correspondin_pole_position_number"),
          width: "",
        },
        {
          prop: "equipmentState",
          label: this.$t("list.state"),
          width: "",
          formatter: (row, column) => {
            if (row.equipmentState == 1) {
              return this.$t("list.go_online");
            } else {
              return this.$t("list.Offline");
            }
          },
        },
        {
          prop: "alphaOperateTypeStr",
          label: this.$t("list.Equipment_yard_operation"),
          width: "",
          formatter: (row, column) => {
            if (row.equipmentSource != 0) {
              return (
                <span>
                  {row.alphaOperateTypeStr} - {row.alphaOperateStateStr}
                </span>
              );
            } else {
              return "";
            }
          },
        },
        {
          prop: "updatedTime",
          label: this.$t("list.update_time"),
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        parkId: this.$route.query.parkId,
        equipmentCode: "",
        barCode: "",
        equipmentState: "",
        equipmentName: "",
      },
    };
  },
  methods: {
    success() {
      this.searchData();
    },
    handleSelectionChange(selection) {
      this.hasSelectedList = [];
      selection.forEach((value) => {
        this.hasSelectedList.push(value.equipmentId);
      });
    },
    multiDelete() {
      if (!this.hasSelectedList.length) {
        this.$alert("请至少选择一条数据！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      } else {
        this.deleteEquips();
      }
    },
    multiUpdataState(state) {
      if (!this.hasSelectedList.length) {
        this.$alert("请至少选择一条数据！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      } else {
        this.$confirm("此操作将更改设备状态，是否继续?", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.updataState("", state);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
    deleteEquips(id) {
      if (!id) {
        id = this.hasSelectedList.join(",");
      }
      this.$confirm("此操作将删除该条数据, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = "/acb/2.0/equipment/delete/" + id;
          this.$axios.post(url).then((res) => {
            if (res.state == 0) {
              this.$alert("操作成功！", this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
              this.page = 1;
              this.searchData();
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    berthLineOpen() {
      this.dialogVisible = true;
      setTimeout(() => {
        this.$refs.berthline.getEquipment();
        this.$refs.berthline.initPage();
      }, 200);
    },
    addBerth() {
      this.$refs.addBerth.open();
      this.equipmentTypeLists();
    },
    equipmentTypeLists() {
      let url = "/acb/2.0/park/" + this.$route.query.parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          if (data.parkEquipmentType == 1) {
            this.equipmentTypeList = this.equipmentType.slice(0, 2);
          } else if (data.parkEquipmentType == 2) {
            this.equipmentTypeList = this.equipmentType.slice(2, 7);
          } else if (data.parkEquipmentType == 4) {
            this.equipmentTypeList = this.equipmentType.filter(
              (x) => x.code == 11 || x.code == 12
            );
          } else if (data.parkEquipmentType == 5) {
            this.equipmentTypeList = this.equipmentType.filter(
              (x) => x.code == 12
            );
          } else {
            this.equipmentTypeList = this.equipmentType.filter(
              (x) => x.code !== 11 && x.code !== 12
            );
          }
        }
      });
    },
    getBrand() {
      this.$axios
        .get("/acb/2.0/brand/selectList", {
          data: {
            operationId: this.$route.query.operationId,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.brandList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$refs.berthDetail.getDetail(data);
        this.$refs.berthDetail.open();
      }
      if (cmd == "b") {
        this.$refs.editBerth.open();
        this.$refs.editBerth.getDetail(data);
        this.equipmentTypeLists();
      }
      if (cmd == "c") {
        this.$confirm("此操作将更改设备状态, 是否继续?", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (data.equipmentState == 1) {
              this.updataState(data.equipmentId, 2);
            } else {
              this.updataState(data.equipmentId, 1);
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
      if (cmd == "d") {
        this.deleteEquips(data.equipmentId);
      }
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      this.$axios
        .get("/acb/2.0/equipment/list", {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.value) {
              this.tableData = res.value.list;
              this.total = res.value.total * 1;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    updataState(id, state) {
      if (!id) {
        id = this.hasSelectedList.join(",");
      }
      let url =
        "/acb/2.0/equipment/updateEquipmentState/" +
        id +
        "/" +
        state +
        "?parkId=" +
        this.$route.query.parkId;
      this.$axios.post(url).then((res) => {
        if (res.state == 0) {
          this.$alert("操作成功！", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          }).then(() => {
            this.searchData();
          });
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    getSelectedList() {
      this.$axios.get("/acb/2.0/equipment/getAddOption").then((res) => {
        if (res.state == 0) {
          if (res.value) {
            this.equipmentType = res.value.equipmentType;
            this.traceTag = res.value.traceTag;
          }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    init() {
      this.$refs.form && this.$refs.form.resetFields();
      this.searchData();
      this.getSelectedList();
      this.getBrand();
    },
    exportFile() {
      exportExcelNew("/acb/2.0/equipment/export", {
        parkId: this.$route.query.parkId,
      });
    },
    openMuli() {
      this.$refs.upload.clearFiles();
      this.$refs.upload.open();
    },
  },
  beforeDestroy() {},
  components: {
    uploadFile,
    addBerth,
    editBerth,
    berthDetail,
    berthLinesetting,
  },
  created() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}
</style>
