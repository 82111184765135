<template>
  <div>
    <el-dialog
      title="编辑杆位"
      :visible.sync="dialogVisible"
      width="500"
      :show-close="false"
      :close-on-click-modal="false"
      custom-class="dialog"
    >
      <el-form ref="form" :model="formInline" label-width="120px" :rules="rules" style="padding: 0">
        <el-form-item :label="$t('searchModule.Pole_position_number')" prop="barCode">
          <el-input :maxlength="30" v-model="formInline.barCode" class="formWidth"></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Pole_position_yourself')" prop="own">
          <el-radio-group v-model="formInline.own">
            <el-radio :label="0">自有杆</el-radio>
            <el-radio :label="1">外部杆</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="经纬度" prop="lnglat">
          <el-input
            :maxlength="20"
            style="width: 220px"
            v-model.trim="lnglat"
            placeholder="在地图上选择坐标点"
          ></el-input>
          <el-button type="primary" @click="openMap">地图选点 </el-button>
        </el-form-item>
        <el-form-item label="视频设备" prop="">
          <el-transfer
            target-order="push"
            :titles="['未选择', '已选择']"
            filterable
            ref="transfer1"
            filter-placeholder="请输入内容"
            :props="{ label: 'equipmentName', key: 'equimentId' }"
            v-model="selectedVideo"
            :data="videoList"
          >
          </el-transfer>
        </el-form-item>
        <el-form-item label="监控设备" prop="">
          <el-transfer
            target-order="push"
            :titles="['未选择', '已选择']"
            filterable
            ref="transfer2"
            :props="{ label: 'monitorEquipmentName', key: 'monitorEquipmentId' }"
            filter-placeholder="请输入..."
            v-model="selectedMonitor"
            :data="monitor"
          >
          </el-transfer>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="save">{{ $t('button.preservation') }}</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
    <map-cona
      ref="mapConpent2"
      :lnglat="lnglat"
      @mapCancel="mapCancel"
      @mapSure="mapSure"
    ></map-cona>
  </div>
</template>
<script>
import mapCona from "./map2";
export default {
  data() {
    let checkCode = (rule, value, callback) => {
      if (/^[A-z0-9]+$/.test(value)) {
        callback();
      } else {
        callback(new Error("支持字母和数字！"));
      }
    };
    return {
      lnglat: "",
      barId: "",
      selectedVideo: [],
      selectedMonitor: [],
      videoList: [],
      monitor: [],
      dialogVisible: false,
      data2: [],
      formInline: {
        barCode: "",
        equipmentIds: "",
        monitorEquipmentIds: "",
        latitude: "",
        longitude: "",
        own: 0,
      },
      rules: {
        barCode: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
          { validator: checkCode, trigger: "blur" },
        ],
        own: [
          {
            required: true,
            message: "必填",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  watch: {
    lnglat(data) {
      if (data) {
        // console.log(data)
        let arr = data.split(",");
        this.formInline.longitude = Math.round(arr[0] * 1000000);
        this.formInline.latitude = Math.round(arr[1] * 1000000);
      }
    },
  },
  components: {
    mapCona,
  },
  props: ["videoEquipment", "monitorEquipment"],
  methods: {
    openMap() {
      //  let city = this.treeData[0].areaName;
      this.$refs.mapConpent2.openMap();
    },
    mapCancel() {
      this.$refs.mapConpent2.mapClose();
      // this.formInline.parkAddress = '';
    },
    mapSure(opt) {
      this.formInline.latitude = Math.round(opt.temLat * 1000000);
      this.formInline.longitude = Math.round(opt.temLng * 1000000);
      // this.formInline.parkAddress = opt.parkAddress;
      this.lnglat = opt.hasSelect;
      this.$refs.mapConpent2.mapClose();
    },
    open() {
      this.$refs.transfer1 && this.$refs.transfer1.clearQuery("left");
      this.$refs.transfer1 && this.$refs.transfer1.clearQuery("right");
      this.$refs.transfer2 && this.$refs.transfer2.clearQuery("left");
      this.$refs.transfer2 && this.$refs.transfer2.clearQuery("right");
      this.dialogVisible = true;
      this.selectedVideo = [];
      this.selectedMonitor = [];
      this.formInline.barCode = "";
    },
    getDetail({ barId }) {
      this.lnglat = "";
      this.barId = barId;
      let url = "/acb/2.0/bar/detail";
      this.$axios
        .get(url, {
          data: {
            barId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline.barCode = res.value.barCode;
            this.formInline.own = res.value.own;
            let log = res.value.longitude || "";
            let lat = res.value.latitude || "";
            if (log && lat) {
              this.lnglat = log / 1000000 + "," + lat / 1000000;
            } else {
              this.lnglat = "";
            }
            this.formInline.own = res.value.own;
            if (res.value.equipmentVoList.length) {
              this.videoList = [...this.videoEquipment, ...res.value.equipmentVoList];
              res.value.equipmentVoList.forEach((v) => {
                this.selectedVideo.push(v.equimentId);
              });
            } else {
              this.videoList = this.videoEquipment;
            }
            if (res.value.monitorEquipmentVoList.length) {
              this.monitor = [...this.monitorEquipment, ...res.value.monitorEquipmentVoList];
              res.value.monitorEquipmentVoList.forEach((v) => {
                this.selectedMonitor.push(v.monitorEquipmentId);
              });
            } else {
              this.monitor = this.monitorEquipment;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    save() {
      this.formInline.equipmentIds = this.selectedVideo.join(",");
      this.formInline.monitorEquipmentIds = this.selectedMonitor.join(",");
      this.$refs.form.validate((valid) => {
        var re = /^[0-9]+.?[0-9]*/;
        if (!re.test(this.formInline.longitude) && this.lnglat !== "") {
          this.$alert("请输入正确的经纬度！", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        } else if (!re.test(this.formInline.latitude) && this.lnglat !== "") {
          this.$alert("请输入正确的经纬度！", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        if (this.lnglat == "") {
          this.formInline.longitude = "";
          this.formInline.latitude = "";
        }
        if (valid) {
          this.$axios
            .post("/acb/2.0/bar/update", {
              data: {
                barId: this.barId,
                parkId: this.$route.query.parkId,
                ...this.formInline,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.dialogVisible = false;
                this.$emit("searchData");
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        }
      });
    },
  },
  created() {},
};
</script>
<style>
.formWidth {
  width: 221.5px;
}

</style>
