<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          ref="form"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="收费员姓名" prop="pdaManagerName">
                <el-input
                  v-model="formInline.pdaManagerName"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Login_Name')" prop="account">
                <el-input
                  v-model="formInline.account"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                v-if="authority.button.add"
                type="primary"
                icon="el-icon-plus"
                @click="addBerth"
                >{{ $t('button.addto') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <!-- <el-table-column type="index" :label="$t('list.index')" width="70" align='center'></el-table-column> -->
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <el-table-column
            :label="this.$t('list.operation')"
            v-if="authority.button.delete"
            align="center"
            width="80"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <!-- <el-dropdown-item command='a'>查看</el-dropdown-item>
                  <el-dropdown-item command='b'>编辑</el-dropdown-item> -->
                  <el-dropdown-item command="c">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!-- <div style='margin-top:10px;'>
            <el-button type="primary" @click="page=1;searchData()">删除</el-button>
        </div> -->
        <add-berth ref="addBerth" @searchData="searchData"></add-berth>
        <edit-berth ref="editBerth"></edit-berth>
        <berth-detail ref="berthDetail"></berth-detail>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import addBerth from "./add";
import editBerth from "./edit";
import berthDetail from "./Detail";
import { dateFormat } from "../../../../../common/js/public.js";
export default {
  name: "hello",
  props: ["authority"],
  data() {
    return {
      parkTypeAll: [],
      workStateAll: [],
      pName: "",
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      parkList: [],
      tenantList: [],
      rsTypeList: {},
      tableCols: [
        {
          prop: "pdaManagerName",
          label: this.$t("list.toll_collector_name"),
          width: "",
        },
        {
          prop: "pdaManagerCode",
          label: this.$t("list.Employee_ID"),
          width: "",
        },
        {
          prop: "account",
          label: this.$t("list.PDA_account"),
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        account: "",
        pdaManagerName: "",
      },
    };
  },
  methods: {
    addBerth() {
      this.$refs.addBerth.open();
    },
    deleteData({ pdaManagerId }) {
      this.$confirm("确定要将该收费员从此停车场中删除吗？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post(
              "/acb/2.0/pdaManagerPark/delete/" +
                this.$route.query.parkId +
                "/" +
                pdaManagerId
            )
            .then((res) => {
              if (res.state == 0) {
                if (res.value) {
                  this.$alert("操作成功！", this.$t('pop_up.Tips'), {
                    confirmButtonText: this.$t('pop_up.Determine'),
                  });
                  this.searchData();
                }
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$refs.berthDetail.open();
      }
      if (cmd == "b") {
        this.$refs.editBerth.open();
      }
      if (cmd == "c") {
        this.deleteData(data);
      }
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    showLog(data) {
      if (data && data.length === 2) {
        let startTime = dateFormat(data[0], "yyyy-MM-dd HH:mm:ss");
        let endTime = dateFormat(data[1], "yyyy-MM-dd HH:mm:ss");
        this.formInline.startTime = startTime;
        this.formInline.endTime = endTime;
      } else {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
      }
    },
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      this.$axios
        .get("/acb/2.0/pdaManager/query", {
          data: {
            parkId: this.$route.query.parkId,
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            if (res.value) {
              this.tableData = res.value.list;
              this.total = res.value.total * 1;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    init() {
      this.$refs.form && this.$refs.form.resetFields();
      this.searchData();
    },
  },
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
  components: {
    addBerth,
    editBerth,
    berthDetail,
  },
  created() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    padding: 22px 22px 0;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}
</style>
