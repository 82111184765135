<template>
  <div>
    <el-dialog
      title="编辑设备"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="500"
      :show-close="false"
      custom-class="dialog"
    >
      <el-form ref="form" :rules="rules" :model="formInline" label-width="120px" style="padding: 0">
        <div style="float: left">
          <el-form-item :label="$t('searchModule.Equipment_name')" prop="equipmentName">
            <el-input
              :maxlength="20"
              v-model="formInline.equipmentName"
              placeholder="请输入"
              class="formWidth"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Equipment_type')" prop="equipmentType">
            <el-select v-model="formInline.equipmentType" placeholder="请选择">
              <el-option
                :label="value.desc"
                :value="value.code"
                :key="value.code"
                v-for="value in equipmentTypeList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备功能" prop="traceTag">
            <el-select v-model="formInline.traceTag" placeholder="请选择" @change="functionChange">
              <el-option
                :label="value.desc"
                :value="value.code"
                :key="value.code"
                v-for="value in traceTag"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div style="float: left">
          <el-form-item label="设备编码" prop="equipmentCode">
            <el-input
              :maxlength="50"
              disabled
              v-model="formInline.equipmentCode"
              placeholder="请选择"
              class="formWidth"
            ></el-input>
          </el-form-item>

          <el-form-item label="设备品牌" prop="brandId">
            <el-select v-model="formInline.brandId" placeholder="请选择">
              <el-option
                :label="value.brandFullName"
                :value="value.brandId"
                :key="value.brandId"
                v-for="value in brandList"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('searchModule.remarks')" prop="description">
            <el-input
              :maxlength="50"
              placeholder="请输入"
              v-model="formInline.description"
              class="formWidth"
            ></el-input>
          </el-form-item>
        </div>
        <div style="clear: both"></div>
        <el-form-item v-show="formInline.traceTag !== ''" label="对应泊位号" prop="">
          <el-transfer
            target-order="push"
            :titles="['未选择', '已选择']"
            filterable
            ref="transfer"
            filter-placeholder="请输入内容"
            :props="{ label: 'berthCode', key: 'berthId' }"
            v-model="formInline.equipmentBerth"
            :data="data2"
          >
          </el-transfer>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="sure">{{ $t("button.preservation") }}</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      equipmentTypeList: [],
      temData: {},
      curItem: "",
      dialogVisible: false,
      hasSelectedBerth: [],
      rules: {
        traceTag: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        equipmentCode: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur",
          },
        ],
        equipmentName: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur",
          },
        ],
        equipmentType: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        brandId: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        equipmentBerth: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
      formInline: {
        parkId: this.$route.query.parkId,
        equipmentCode: "",
        equipmentName: "",
        equipmentType: "",
        brandId: "",
        traceTag: "",
        description: "",
        equipmentBerth: [],
        equipmentBar: "",
      },
      data2: [],
    };
  },
  props: ["equipmentType", "traceTag", "brandList"],
  watch: {
    equipmentType: {
      handler(v) {
        this.equipmentTypeList = v;
      },
      deep: true,
    },
  },
  methods: {
    open() {
      this.$refs.transfer && this.$refs.transfer.clearQuery("left");
      this.$refs.transfer && this.$refs.transfer.clearQuery("right");
      this.$refs.form && this.$refs.form.resetFields();
      this.formInline.equipmentBerth = [];
      this.dialogVisible = true;
    },
    getDetail({ equipmentId }) {
      this.equipmentId = equipmentId;
      let url = "/acb/2.0/equipment/detail";
      this.$axios
        .get(url, {
          data: {
            equipmentId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            for (let i in this.formInline) {
              this.formInline[i] = res.value[i];
            }
            this.formInline.equipmentBerth = [];
            if (!res.value.berthList) {
              this.formInline.equipmentBerth = [];
            } else {
              res.value.berthList.forEach((v) => {
                this.formInline.equipmentBerth.push(v.berthId);
              });
            }
            this.temData = res.value;
            let isHaveEquipmentType = this.equipmentTypeList.findIndex(
              (v) => v.code === this.formInline.equipmentType
            );
            if (isHaveEquipmentType < 0) {
              this.formInline.equipmentType = res.value.equipmentTypeName;
            }
            this.functionChange(this.formInline.traceTag, res.value.berthList);
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    functionChange(item, berthList) {
      if (!berthList) {
        this.formInline.equipmentBerth = [];
      }
      if (item == this.temData.traceTag && !berthList) {
        berthList = this.temData.berthList;
        this.temData.berthList.forEach((v) => {
          this.formInline.equipmentBerth.push(v.berthId);
        });
      }
      this.$axios
        .get("/acb/2.0/equipment/listUnboundEquipmentBerths", {
          data: {
            parkId: this.$route.query.parkId,
            traceTag: item,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.data2 = [...res.value, ...berthList];
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    sure() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (typeof this.formInline.equipmentType === "string") {
            this.formInline.equipmentType = this.temData.equipmentType;
          }
          let data = {
            ...this.formInline,
          };
          data.equipmentBerth = this.formInline.equipmentBerth.join(",");
          this.$axios
            .post("/acb/2.0/equipment/update", {
              data: {
                equipmentId: this.equipmentId,
                ...data,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.dialogVisible = false;
                this.$emit("searchData");
              } else {
                this.$alert(res.desc);
              }
            });
        } else {
        }
      });
    },
  },
  mounted() {},
};
</script>
<style>
.formWidth {
  width: 221.5px;
}
</style>
