<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          ref="form"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Rule_Name')" prop="titleStr">
                <el-input
                  size="12"
                  :maxlength="50"
                  v-model.trim="formInline.titleStr"
                  placeholder="请输入规则名称"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            :label="this.$t('list.index')"
            width="70"
            align="center"
          ></el-table-column>
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            :show-overflow-tooltip="item.prop === 'descMsg'"
            align="center"
          ></el-table-column>
          <!-- <el-table-column
            label="操作"
            align="center"
            width="80"
            v-if="authority.button.view || authority.button.edit || authority.button.delete"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="authority.button.view" command="a">查看</el-dropdown-item>
                  <el-dropdown-item v-if="authority.button.edit" command="b">编辑</el-dropdown-item>
                  <el-dropdown-item v-if="authority.button.delete" command="c"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column> -->
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <add-berth ref="addBerth" @searchData="searchData"></add-berth>
        <edit-berth ref="editBerth" @searchData="searchData"></edit-berth>
        <berth-detail ref="berthDetail"></berth-detail>
      </div>
    </div>
  </div>
</template>

<script>
import addBerth from "./add";
import editBerth from "./edit";
import berthDetail from "./Detail";
import //
// dateFormat
"../../../../../common/js/public.js";
export default {
  name: "hello",
  props: ["authority"],
  data() {
    return {
      parkTypeAll: [],
      workStateAll: [],
      pName: "",
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      feeList: [],
      parkList: [],
      tenantList: [],
      rsTypeList: {},
      tableCols: [
        {
          prop: "title",
          label: this.$t("list.Rule_Name"),
          width: "",
        },
        {
          prop: "descMsg",
          label: this.$t("list.Rule_description"),
          width: "",
        },
        {
          prop: "bindDate",
          label: this.$t("list.Binding_time"),
          width: "",
        },
        {
          prop: "bindOperatorName",
          label: this.$t("list.Bind_operator"),
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        titleStr: "",
      },
    };
  },
  methods: {
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    addBerth() {
      this.$refs.addBerth.open();
    },
    getFeeList() {
      let url = "/acb/2.0/fee/operationId";
      this.$axios
        .get(url, {
          data: {
            operationId: this.$route.query.operationId,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.feeList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$refs.berthDetail.getDetail(data.feeSceneId);
        this.$refs.berthDetail.open();
      }
      if (cmd == "b") {
        this.$refs.editBerth.getDetail(data.feeSceneId);
        this.$refs.editBerth.open();
      }
      if (cmd == "c") {
        this.deleteCharge(data);
      }
    },
    deleteCharge({ feeSceneId }) {
      this.$confirm(
        "不建议换绑计费规则，因为换绑计费规则后，会导致异常处理按新规则计费。请确认换绑计费规则吗？",
        this.$t('pop_up.Tips'),
        {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          let url = "/acb/2.0/feeScene/delete/" + feeSceneId;
          this.$axios.post(url).then((res) => {
            if (res.state == 0) {
              this.$alert("删除成功！", this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
              this.searchData();
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 搜索
    searchData() {
      this.loading = true;
      let url = "/acb/2.0/roadInEngine/queryFeeList";
      this.$axios
        .post(url, {
          data: {
            pageNum: this.page,
            pageSize: this.pageSize,
            parkId: this.$route.query.parkId,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            if (res.value) {
              this.tableData = res.value.list ?? [];
              this.total = res.value.total * 1 ?? 0;
            } else {
              this.tableData = [];
              this.total = 0;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    init() {
      this.$refs.form && this.$refs.form.resetFields();
      // this.getFeeList();
      this.searchData();
    },
  },
  beforeDestroy() {},
  components: {
    addBerth,
    editBerth,
    berthDetail,
  },
  created() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    padding: 22px 22px 0;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}
</style>
