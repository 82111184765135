<template>
  <div>
    <el-dialog title="查看泊位" :visible.sync="dialogVisible" width="620px" custom-class="dialog">
      <el-form ref="berthform" :model="formInline" label-width="120px" style="padding: 0">
        <el-form-item label="泊位编号：" prop="berthCode">
          <span>{{ formInline.berthCode }}</span>
        </el-form-item>
        <el-form-item label="经纬度：" prop="berthCode">
          <span>{{ this.lng }}</span>
        </el-form-item>
        <el-form-item label="服务标签：">
          <el-checkbox-group v-model="serviceAttr">
            <el-checkbox label="1" disabled>潮汐泊位</el-checkbox>
            <el-checkbox label="2" disabled>共享泊位</el-checkbox>
            <el-checkbox label="3" disabled>充电泊位</el-checkbox>
            <el-checkbox label="4" disabled>残疾人泊位</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="泊位类型：" prop="berthType">
          <span>{{ formInline.berthTypeName }}</span>
        </el-form-item>
        <el-form-item label="计费规则：" prop="">
          <div class="listStyleWrapper">
            <div v-for="v in formInline.feeList" class="listStyle" :key="v.feeCode">
              {{ v.feeName }}
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <template v-if="formInline.parkName && formInline.parkCode">
          <div class="equipment-title">
            <span class="line"></span>
            <span class="text">设备车场</span>
            <span class="line"></span>
          </div>
          <div class="equipment">
            名称：{{ formInline.parkName }} &nbsp;&nbsp;编号：{{ formInline.parkCode }}
          </div>
        </template>
        <el-button @click="dialogVisible = false">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lng: "",
      serviceAttr: [],
      dialogVisible: false,
      formInline: {
        parkId: this.$route.query.parkId,
        feeList: [{ feeCode: 123, description: "无" }],
        berthCode: "",
        batchAddNum: "",
        berthQuality: "",
        berthType: "",
        berthDisability: 0,
        electricBerth: 0,
        serviceTypeShare: "0",
        serviceTypeTide: "0",
        charge: "",
        parkName: "",
        parkCode: "",
      },
      data2: [],
    };
  },
  props: ["qualityState", "berthType"],
  methods: {
    open() {
      this.dialogVisible = true;
      this.serviceAttr = [];
    },
    getDetail({ berthId }) {
      this.lng = "";
      this.$axios
        .get("/acb/2.0/berth/detail", {
          data: {
            berthId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline = res.value.berth;
            let log = res.value.berth.longitude || "";
            let lat = res.value.berth.latitude || "";
            if (log || lat) {
              this.lng = log / 1000000 + "," + lat / 1000000;
            }
            if (this.formInline.serviceTypeShare == 1) {
              this.serviceAttr.push("2");
            }
            if (this.formInline.serviceTypeTide == 1) {
              this.serviceAttr.push("1");
            }
            if (this.formInline.charge == 1) {
              this.serviceAttr.push("3");
            }
            if (this.formInline.berthDisability == 1) {
              this.serviceAttr.push("4");
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.formWidth {
  width: 221.5px;
}

.listStyle {
  text-align: center;
  border-bottom: none;
  background: rgb(237, 241, 244);
}
.listStyleWrapper {
  width: 255px;
  min-height: 40px;
  height: auto;
  background: #fefefe;
  border: 1px solid rgb(204, 204, 204);
  overflow: auto;
}
.equipment, .equipment-title {
  margin-bottom: 15px;
  position: relative;
}
.equipment-title {
  .line {
    display: inline-block;
    width: 40%;
    height: 1px;
    background : #ccc;
    vertical-align: middle;
  }
  .text {
    display: inline-block;
    margin: 0 10px;
  }
}
</style>
