<template>
  <div>
    <el-dialog
      title="查看设备"
      :visible.sync="dialogVisible"
      width="788px"
      :show-close="false"
      custom-class="dialog"
    >
      <el-form ref="form" :rules="rules" :model="formInline" label-width="120px" style="padding: 0">
        <div style="display: flex">
          <div style="flex: 1">
            <el-form-item label="设备名称：" prop="equipmentType">
              <span class="of" :title="formInline.equipmentName">
                {{ formInline.equipmentName }}</span
              >
            </el-form-item>
            <el-form-item label="设备类型：" prop="equipmentType">
              {{ formInline.equipmentTypeName }}
            </el-form-item>
            <el-form-item label="设备功能：" prop="traceTag">
              {{ formInline.traceTagName }}
            </el-form-item>
          </div>
          <div style="flex: 1">
            <el-form-item label="设备编码：" prop="equipmentCode">
              <span class="of" :title="formInline.equipmentCode">
                {{ formInline.equipmentCode }}</span
              >
            </el-form-item>

            <el-form-item label="设备品牌：" prop="brandId">
              {{ formInline.brandName }}
            </el-form-item>

            <el-form-item label="备注：" prop="description">
              <span class="of" :title="formInline.description"> {{ formInline.description }} </span>
            </el-form-item>
          </div>
        </div>
        <el-form-item v-show="formInline.traceTag !== ''" label="对应泊位号:" prop="">
          <div class="listStyleWrapper">
            <div v-for="v in hasSelectedBerth" class="listStyle" :key="v.berthId">
              {{ v.berthCode }}
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible = false">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      hasSelectedBerth: [{ berthId: 123, berthCode: "无" }],
      rules: {
        traceTag: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        equipmentCode: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur",
          },
        ],
        equipmentName: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur",
          },
        ],
        equipmentType: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        brandId: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        equipmentBerth: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
      formInline: {
        parkId: this.$route.query.parkId,
        equipmentCode: "",
        equipmentName: "",
        equipmentType: "",
        brandId: "",
        traceTag: "",
        description: "",
        equipmentBerth: [],
        equipmentBar: "",
      },
      data2: [],
    };
  },
  props: ["equipmentType", "traceTag", "brandList"],
  methods: {
    open() {
      this.$refs.form && this.$refs.form.resetFields();
      this.dialogVisible = true;
      this.hasSelectedBerth = [];
    },
    getDetail({ equipmentId }) {
      let url = "/acb/2.0/equipment/detail";
      this.$axios
        .get(url, {
          data: {
            equipmentId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline = res.value;
            this.formInline.equipmentBerth = [];
            if (!res.value.berthList.length) {
              this.formInline.equipmentBerth = [];
            } else {
              this.hasSelectedBerth = res.value.berthList;
            }
            this.functionChange(this.formInline.traceTag, res.value.berthList);
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    functionChange(item, berthList) {
      this.$axios
        .get("/acb/2.0/equipment/listUnboundEquipmentBerths", {
          data: {
            parkId: this.$route.query.parkId,
            traceTag: item,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (berthList.length) {
              this.data2 = [...res.value, ...berthList];
            } else {
              this.data2 = res.value;
            }
            this.data2.forEach((v) => {
              v.disabled = true;
            });
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    sure() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$axios
            .post("/acb/2.0/equipment/add", {
              data: this.formInline,
            })
            .then((res) => {
              if (res.state == 0) {
                this.dialogVisible = false;
                this.$emit("searchData");
              } else {
                this.$alert(res.desc, this.$t("pop_up.Tips"), {
                  confirmButtonText: this.$t("pop_up.Determine"),
                });
              }
            });
        } else {
        }
      });
    },
  },
  mounted() {},
};
</script>
<style scoped>
.formWidth {
  width: 221.5px;
}

.listStyle {
  text-align: center;
  border-bottom: 1px solid rgb(204, 204, 204);
  background: rgb(237, 241, 244);
}
.listStyleWrapper {
  width: 255px;
  height: 250px;
  background: #fefefe;
  border: 1px solid rgb(204, 204, 204);
  overflow: auto;
}
.of {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: 250px;
}
</style>
