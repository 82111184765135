var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看泊位",
            visible: _vm.dialogVisible,
            width: "620px",
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "berthform",
              staticStyle: { padding: "0" },
              attrs: { model: _vm.formInline, "label-width": "120px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "泊位编号：", prop: "berthCode" } },
                [_c("span", [_vm._v(_vm._s(_vm.formInline.berthCode))])]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "经纬度：", prop: "berthCode" } },
                [_c("span", [_vm._v(_vm._s(this.lng))])]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "服务标签：" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.serviceAttr,
                        callback: function ($$v) {
                          _vm.serviceAttr = $$v
                        },
                        expression: "serviceAttr",
                      },
                    },
                    [
                      _c(
                        "el-checkbox",
                        { attrs: { label: "1", disabled: "" } },
                        [_vm._v("潮汐泊位")]
                      ),
                      _c(
                        "el-checkbox",
                        { attrs: { label: "2", disabled: "" } },
                        [_vm._v("共享泊位")]
                      ),
                      _c(
                        "el-checkbox",
                        { attrs: { label: "3", disabled: "" } },
                        [_vm._v("充电泊位")]
                      ),
                      _c(
                        "el-checkbox",
                        { attrs: { label: "4", disabled: "" } },
                        [_vm._v("残疾人泊位")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "泊位类型：", prop: "berthType" } },
                [_c("span", [_vm._v(_vm._s(_vm.formInline.berthTypeName))])]
              ),
              _c("el-form-item", { attrs: { label: "计费规则：", prop: "" } }, [
                _c(
                  "div",
                  { staticClass: "listStyleWrapper" },
                  _vm._l(_vm.formInline.feeList, function (v) {
                    return _c(
                      "div",
                      { key: v.feeCode, staticClass: "listStyle" },
                      [_vm._v(" " + _vm._s(v.feeName) + " ")]
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _vm.formInline.parkName && _vm.formInline.parkCode
                ? [
                    _c("div", { staticClass: "equipment-title" }, [
                      _c("span", { staticClass: "line" }),
                      _c("span", { staticClass: "text" }, [_vm._v("设备车场")]),
                      _c("span", { staticClass: "line" }),
                    ]),
                    _c("div", { staticClass: "equipment" }, [
                      _vm._v(
                        " 名称：" +
                          _vm._s(_vm.formInline.parkName) +
                          "   编号：" +
                          _vm._s(_vm.formInline.parkCode) +
                          " "
                      ),
                    ]),
                  ]
                : _vm._e(),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("返回")]
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }