<template>
  <div>
    <el-dialog
      title="添加泊位"
      :visible.sync="dialogVisible"
      width="620px"
      :show-close="false"
      :close-on-click-modal="false"
      custom-class="dialog"
      :modal-append-to-body="false"
    >
      <el-form
        ref="berthform"
        :rules="rules"
        :model="formInline"
        label-width="150px"
        style="padding: 0"
      >
        <el-form-item :label="$t('searchModule.Berth_number')" prop="berthCode">
          <el-input :maxlength="30" v-model="formInline.berthCode" class="formWidth"></el-input>
        </el-form-item>
        <el-form-item label="批量添加泊位数" prop="batchAddNum">
          <el-input
            :maxlength="3"
            v-model.number="formInline.batchAddNum"
            class="formWidth"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Service_label')">
          <el-checkbox-group v-model="serviceAttr">
            <el-checkbox label="1">潮汐泊位</el-checkbox>
            <el-checkbox label="2">共享泊位</el-checkbox>
            <el-checkbox label="3">充电泊位</el-checkbox>
            <el-checkbox label="4">残疾人泊位</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Berth_type')" prop="berthType">
          <el-radio-group v-model="formInline.berthType">
            <el-radio :label="value.code" :key="value.code" v-for="value in berthType">{{
              value.desc
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="经纬度" prop="longitude">
          <el-input
            :maxlength="20"
            style="width: 220px"
            v-model.trim="lnglat"
            placeholder="在地图上选择坐标点"
          ></el-input>
          <el-button
            type="primary"
            class="map1"
            style="margin-top: 0; margin-right: -12px"
            @click="openMap"
            >地图选点
          </el-button>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" :loading="loading" @click="submitData">{{ $t('button.preservation') }}</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
    <map-con ref="mapConpent" @mapCancel="mapCancel" @mapSure="mapSure"></map-con>
  </div>
</template>
<script>
import mapCon from "./map";
export default {
  data() {
    let checkCode = (rule, value, callback) => {
      if (!this.formInline.batchAddNum || this.formInline.batchAddNum == 1) {
        if (/[A-z0-9]/.test(value)) {
          callback();
        } else {
          callback(new Error("支持字母和数字"));
        }
      } else {
        if (/[A-z0-9]*?\d{4}$/.test(value)) {
          callback();
        } else {
          callback(new Error("支持字母和数字且4位数字结尾"));
        }
      }
    };
    let checkNum = (rule, value, callback) => {
      if (!value && value !== 0) {
        return callback();
      }
      if (value != parseInt(value)) {
        return callback(new Error("必须为整数！"));
      }
      if (value < 2) {
        callback(new Error("必须大于1"));
      } else if (value > 100) {
        callback(new Error("不能大于100"));
      } else {
        callback();
      }
    };
    return {
      lnglat: "",
      loading: false,
      serviceAttr: [],
      dialogVisible: false,
      formInline: {
        parkId: this.$route.query.parkId,
        berthCode: "",
        batchAddNum: "",
        berthQuality: "1",
        berthType: "",
        berthDisability: 0,
        charge: 0,
        serviceTypeShare: "0",
        serviceTypeTide: "0",
        latitude: "",
        longitude: "",
      },
      data2: [],
      rules: {
        berthCode: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
          { validator: checkCode, trigger: "blur" },
        ],
        batchAddNum: [{ validator: checkNum, trigger: "blur" }],
        berthQuality: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        berthType: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        berthDisability: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    dialogVisible(data) {
      if (data === false) {
        this.lnglat = "";
      }
    },
    lnglat(data) {
      if (data) {
        let arr = data.split(",");
        this.formInline.longitude = Math.round(arr[0] * 1000000);
        this.formInline.latitude = Math.round(arr[1] * 1000000);
      }
    },
  },
  props: ["qualityState", "berthType", "brandList"],
  components: {
    mapCon,
  },
  methods: {
    created() {
      this.lnglat = "";
    },
    openMap() {
      //  let city = this.treeData[0].areaName;
      this.$refs.mapConpent.openMap();
    },
    mapCancel() {
      this.$refs.mapConpent.mapClose();
      // this.formInline.parkAddress = '';
      // this.lnglat = '';
    },
    mapSure(opt) {
      if (this.lnglat == "") {
        this.formInline.latitude = "";
        this.formInline.latitude = "";
      }
      this.formInline.latitude = Math.round(opt.temLat * 1000000);
      this.formInline.longitude = Math.round(opt.temLng * 1000000);
      // this.formInline.parkAddress = opt.parkAddress;
      this.lnglat = opt.hasSelect;
      this.$refs.mapConpent.mapClose();
    },
    open() {
      this.formInline.berthQuality = "1";
      this.serviceAttr = [];
      this.dialogVisible = true;
      this.$refs.berthform && this.$refs.berthform.resetFields();
      this.loading = false;
    },
    submitData() {
      this.$refs.berthform.validate((valid) => {
        var re = /^[0-9]+.?[0-9]*/;
        if (!re.test(this.formInline.longitude) && this.lnglat !== "") {
          this.$alert("请输入正确的经纬度！", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        } else if (!re.test(this.formInline.latitude) && this.lnglat !== "") {
          this.$alert("请输入正确的经纬度！", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        if (this.lnglat == "") {
          this.formInline.longitude = "";
          this.formInline.latitude = "";
        }
        if (valid) {
          this.formInline.serviceTypeShare = Number(
            this.serviceAttr.some((v) => {
              return v == 2;
            })
          );
          this.formInline.serviceTypeTide = Number(
            this.serviceAttr.some((v) => {
              return v == 1;
            })
          );
          this.formInline.charge = Number(
            this.serviceAttr.some((v) => {
              return v == 3;
            })
          );
          this.formInline.berthDisability = Number(
            this.serviceAttr.some((v) => {
              return v == 4;
            })
          );
          this.$axios
            .post("/acb/2.0/berth/add", {
              data: this.formInline,
            })
            .then((res) => {
              if (res.state == 0) {
                this.dialogVisible = false;
                this.$emit("searchData");
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
.formWidth {
  width: 221.5px;
}

</style>
