<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          ref="form"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('list.Berth_number')" prop="berthCode">
                <el-input
                  v-model="formInline.berthCode"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Equipment_number')" prop="equipmentName">
                <el-input
                  v-model="formInline.equipmentName"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                type="primary"
                icon="el-icon-plus"
                v-if="authority.button.add"
                @click="addBerth"
                >{{ $t('button.addto') }}</el-button
              >
              <el-button
                type="info"
                plain
                icon="el-icon-upload"
                @click="openMuli"
                v-if="authority.button.batchImport"
                >批量{{ $t('button.import') }}</el-button
              >
              <el-button
                type="info"
                v-if="authority.button.upLine"
                @click="handleOnOffLine('on', {})"
                >上线</el-button
              >
              <!-- v-if='authority.button.delete' -->
              <el-button
                type="info"
                v-if="authority.button.downLine"
                @click="handleOnOffLine('off', {})"
                >下线</el-button
              >
              <el-button
                type="info"
                v-if="authority.button.delete"
                @click="deleteBerth"
                >删除</el-button
              >
            </div>
            <div class="col_right mbd4">
              <el-button
                type="primary"
                plain
                icon="el-icon-download"
                @click="exportFile"
                v-if="authority.button.export"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          class="tb-edit"
          @selection-change="handleSelectionChange"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          highlight-current-row
          @row-click="rowCurrentChange"
        >
          <el-table-column
            type="selection"
            align="center"
            width="55"
          ></el-table-column>
          <!-- <el-table-column type="index" :label="$t('list.index')" width="70" align='center'></el-table-column> -->
          <!--<el-table-column type="index" :label="$t('list.index')" width="150" align='center'>
          	<template scope="scope">
				   		<el-input v-model="scope.$index" placeholder="请输入内容" @change="handleEdit(scope.$index, scope.row)"></el-input>
			   			<span>{{scope.$index}}</span>
				 		</template>
          </el-table-column>-->
          <!--<el-table-column :prop="item.prop" :label="item.label" :width="item.width" v-for="item in tableCols" :key="item.prop" :formatter="item.formatter" align='center'>
          </el-table-column>-->
          <!-- <el-table-column prop="privateIntegerberthOrder" label="泊位排序" width="" align="center">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.berthOrder"
                maxlength="4"
                placeholder="请输入内容"
                @keydown.enter.native="preservation(scope.$index, scope.row, 'enter')"
                @keyup.native="handleEdit(scope.row)"
                @blur="preservation(scope.$index, scope.row, 'blur')"
                v-if="scope.$index === nowIndex"
                ref="inpfocus"
              ></el-input>
              <span
                @click="spanShow(scope.$index, scope.row)"
                :class="{ isShow: scope.$index === nowIndex }"
                style="color: blue; display: inline-block; width: 100%"
                >{{ scope.row.berthOrder }}</span
              >
            </template>
          </el-table-column> -->
          <el-table-column
            prop="berthCode"
            :label="$t('list.Berth_number')"
            width=""
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop=""
            :label="$t('list.Service_label')"
            :formatter="handleFormatter"
            width=""
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="fees"
            :label="$t('list.Charging_rules')"
            width=""
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="lng"
            :label="$t('list.latitude_and_longitude')"
            width=""
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.longitude || scope.row.latitude">
                {{
                  scope.row.longitude / 1000000 +
                  "," +
                  scope.row.latitude / 1000000
                }}
              </span>
              <span v-else></span>
            </template>
          </el-table-column>
          <el-table-column
            prop="equipmentCode"
            :label="$t('list.Equipment_number')"
            width=""
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="equipmentName"
            :label="$t('list.Equipment_yard')"
            width=""
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="berthState"
            :label="$t('list.state')"
            width=""
            align="center"
          >
            <template slot-scope="scope">
              <span
                v-if="scope.row.berthState == 1 || scope.row.berthState == 2"
              >
                {{
                  scope.row.berthState == 1
                    ? $t("list.go_online")
                    : $t("list.Offline")
                }}
              </span>
              <span v-else></span>
            </template>
          </el-table-column>
          <el-table-column
            prop="alphaOperateTypeStr"
            :label="$t('list.Equipment_yard_operation')"
            width=""
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.berthSource != 0">
                {{ scope.row.alphaOperateTypeStr }} -
                {{ scope.row.alphaOperateStateStr }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="updatedTime"
            :label="$t('list.update_time')"
            width=""
            align="center"
          >
          </el-table-column>

          <el-table-column
            :label="$t('list.operation')"
            width="80"
            v-if="
              authority.button.view ||
              authority.button.edit ||
              authority.button.delete
            "
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="authority.button.view" command="a"
                    >查看</el-dropdown-item
                  >
                  <el-dropdown-item v-if="authority.button.edit" command="b"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="c"
                    v-if="authority.button.upLine"
                    v-show="scope.row.berthState == 2"
                    >上线</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="c"
                    v-if="authority.button.downLine"
                    v-show="scope.row.berthState == 1"
                    >下线</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="e"
                    v-if="authority.button.upLine"
                    v-show="
                      scope.row.berthState == 1 && scope.row.berthSource == 1
                    "
                    >临时下线</el-dropdown-item
                  >
                  <el-dropdown-item v-if="authority.button.delete" command="d"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <add-berth
          ref="addBerth"
          :brandList="brandList"
          :berthType="berthType"
          @searchData="searchData"
          :qualityState="qualityState"
        ></add-berth>
        <edit-berth
          ref="editBerth"
          :berthType="berthType"
          @searchData="searchData"
          :qualityState="qualityState"
        ></edit-berth>
        <berth-detail ref="berthDetail"></berth-detail>
      </div>
      <upload-file
        ref="upload"
        :uploadData="uploadData"
        @success="success"
        :uploadUrl="uploadUrl"
        :modelUrl="modelUrl"
      ></upload-file>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import addBerth from "./add";
import editBerth from "./edit";
import berthDetail from "./Detail";
import {
  exportExcelNew,
  //
  // dateFormat,
  // setIndex
} from "@/common/js/public";
import uploadFile from "@/components/uploadFile/";

export default {
  name: "hello",
  props: ["authority"],
  data() {
    return {
      sendData: 0,
      uploadUrl: "/acb/2.0/berth/import",
      modelUrl: "/acb/2.0/berth/downloadExcelTemplate",
      uploadData: {
        parkId: this.$route.query.parkId,
      },
      inpFocus: true,
      selection: [],
      parkTypeAll: [],
      workStateAll: [],
      berthType: [],
      brandList: [],
      qualityState: [],
      nowIndex: "",
      pName: "",
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      parkList: [],
      tenantList: [],
      rsTypeList: {},
      berNum: "",
      tableCols: [
        {
          prop: "berthCode",
          //        label: '泊位号',
          width: "",
        },
        {
          prop: "equipmentName",
          //        label: '对应视频设备',
          width: "",
        },
        {
          prop: "fees",
          //        label: '计费规则',
          width: "",
        },
        {
          prop: "updatedTime",
          //        label: '更新时间',
          width: "",
        },
      ],
      tableData: [],
      test: [],
      formInline: {
        parkId: this.$route.query.parkId,
        berthCode: "",
        equipmentName: "",
      },
    };
  },
  methods: {
    handleFormatter(row, column) {
      let arr = [];
      if (row.serviceTypeTideStr) {
        arr.push(row.serviceTypeTideStr);
      }
      if (row.serviceTypeShareStr) {
        arr.push(row.serviceTypeShareStr);
      }
      if (row.chargeStr) {
        arr.push(row.chargeStr);
      }
      if (row.berthDisabilityStr) {
        arr.push(row.berthDisabilityStr);
      }
      return arr.join("、");
    },
    openMuli() {
      this.$refs.upload.clearFiles();
      this.$refs.upload.open();
    },
    success() {
      this.searchData();
    },
    beforeAvatarUpload(file) {},
    handleSelectionChange(selection) {
      this.selection = selection;
    },
    addBerth() {
      this.$refs.addBerth.open();
    },
    rowCurrentChange(row, event, column) {},
    handleEdit(row) {
      row.berthOrder = row.berthOrder.replace(/[^\d]/g, "");
    },
    spanShow(index, row) {
      this.berNum = row.berthOrder;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        this.$refs.inpfocus.focus();
      }, 100);
      if (this.nowIndex == index) {
        this.nowIndex = "";
      } else {
        this.nowIndex = "";
      }
      if (this.isShow == index) {
        this.nowIndex = "";
      } else {
        this.nowIndex = index;
      }
    },
    preservation(index, row, type) {
      this.sendData++;
      if (type == "enter") {
        for (let i = 0; i < row.berthOrder.length; i++) {
          if (/[a-z]/i.test(row.berthOrder[i]) || row.berthOrder <= 0) {
            // alert('输入格式有误！序号必须大于0');
            row.berthOrder = this.berNum;
            let timer = setTimeout(() => {
              clearTimeout(timer);
              this.$refs.inpfocus.focus();
            }, 100);
            return;
          }
        }
        if (row.berthOrder == "") {
          // alert('输入不能为空')
          row.berthOrder = this.berNum;
          let timer = setTimeout(() => {
            clearTimeout(timer);
            this.$refs.inpfocus.focus();
          }, 100);
        } else {
          this.$axios
            .post("/acb/2.0/berth/update", {
              data: {
                berthId: row.berthId,
                berthCode: row.berthCode,
                berthOrder: row.berthOrder,
                parkId: this.$route.query.parkId,
                serviceTypeShare: "0",
                serviceTypeTide: "0",
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.nowIndex = "";
                this.$alert("操作成功！", this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        }
      } else {
        if (this.sendData >= 2) {
          this.sendData = 0;
          return;
        }
        this.sendData = 0;
        for (let i = 0; i < row.berthOrder.length; i++) {
          if (/[a-z]/i.test(row.berthOrder[i]) || row.berthOrder <= 0) {
            // alert('输入格式有误！序号必须大于0')
            row.berthOrder = this.berNum;
            let timer = setTimeout(() => {
              clearTimeout(timer);
              this.$refs.inpfocus.focus();
            }, 100);
            return;
          }
        }
        if (row.berthOrder == "") {
          // alert('输入不能为空')
          row.berthOrder = this.berNum;
          let timer = setTimeout(() => {
            clearTimeout(timer);
            this.$refs.inpfocus.focus();
          }, 100);
        } else {
          this.$axios
            .post("/acb/2.0/berth/update", {
              data: {
                berthId: row.berthId,
                berthCode: row.berthCode,
                berthOrder: row.berthOrder,
                parkId: this.$route.query.parkId,
                serviceTypeShare: "0",
                serviceTypeTide: "0",
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.nowIndex = "";
                this.$alert("操作成功！", this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        }
      }
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$refs.berthDetail.getDetail(data);
        this.$refs.berthDetail.open();
      }
      if (cmd == "b") {
        this.$refs.editBerth.getDetail(data);
        this.$refs.editBerth.open();
      }
      if (cmd == "d") {
        this.deleteBerth(data);
      }
      if (cmd == "c") {
        let type = data.berthState == 2 ? "on" : "off";
        this.handleOnOffLine(type, data);
      }
      // 临时下线
      if (cmd == "e") {
        this.handleTempOffline(data);
      }
    },
    handleTempOffline({ berthCode, berthId }) {
      console.log("berthCode, berthId", berthCode, berthId);
      this.$confirm(
        `临时下线不影响泊位状态，可以使用PDA管理，您确定要将${berthCode}泊位临时下线吗？`,
        this.$t('pop_up.Tips'),
        {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$axios
            .post("/acb/2.0/berth/berthTempOffline", {
              data: {
                parkId: berthId,
                berthIds: berthCode,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$message({
                  type: "info",
                  message: "临时下线成功！",
                });
                this.page = 1;
                this.searchData();
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    handleOnOffLine(type, { berthId }) {
      if (berthId || this.selection.length) {
      } else {
        this.$alert("请至少选择一条数据！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.$confirm("此操作将更改泊位状态，是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let berthIds = [];
          if (berthId) {
            berthIds = berthId;
          } else {
            this.selection.forEach((v) => {
              berthIds.push(v.berthId);
            });
            berthIds = berthIds.join(",");
          }
          let url = "",
            typeStr = "";
          if (type == "on") {
            url = "/acb/2.0/berth/berthOnline";
            typeStr = "上线";
          } else if (type == "off") {
            url = "/acb/2.0/berth/berthOffline";
            typeStr = "下线";
          }
          this.$axios
            .post(url, {
              data: {
                parkId: this.$route.query.parkId,
                berthIds: berthIds,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$message({
                  type: "info",
                  message: `${typeStr}成功！`,
                });
                this.page = 1;
                this.searchData();
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    deleteBerth({ berthId }) {
      if (berthId || this.selection.length) {
      } else {
        this.$alert("请至少选择一个泊位！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.$confirm("此操作将删除泊位, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let berthIds = [];
          if (berthId) {
            berthIds = berthId;
          } else {
            this.selection.forEach((v) => {
              berthIds.push(v.berthId);
            });
            berthIds = berthIds.join(",");
          }
          this.$axios
            .post(
              "/acb/2.0/berth/delete/" +
                berthIds +
                "/" +
                this.$route.query.parkId
            )
            .then((res) => {
              if (res.state == 0) {
                this.$message({
                  type: "info",
                  message: "删除成功！",
                });
                this.page = 1;
                this.searchData();
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    getSelectedList() {
      this.$axios.get("/acb/2.0/berth/getAddOption").then((res) => {
        this.qualityState = res.value.qualityState;
        this.berthType = res.value.berthType;
      });
    },
    getBrandList() {
      this.$axios
        .get("/acb/2.0/brand/list", {
          data: {
            page: 0,
            pageSize: 0,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.brandList = res.value.list;
          }
        });
    },
    // 搜索
    searchData() {
      this.$axios
        .get("/acb/2.0/berth/list", {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.value) {
              this.tableData = res.value.list;
              this.total = res.value.total * 1;
            } else {
              this.tableData = [];
              this.total = 0;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    init() {
      this.$refs.form && this.$refs.form.resetFields();
      this.getSelectedList();
      this.searchData();
      this.getBrandList();
    },
    exportFile() {
      exportExcelNew("/acb/2.0/berth/export", {
        parkId: this.$route.query.parkId,
      });
    },
  },
  beforeDestroy() {
    // sessionStorage.searchData = JSON.stringify(this.formInline);
    // sessionStorage.searchFlag = 1;
  },
  components: {
    addBerth,
    editBerth,
    berthDetail,
    uploadFile,
  },
  created() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}
</style>
<style scoped>
.checked {
  display: block !important;
}
.isShow {
  display: none !important;
}
</style>
