var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加设备",
            visible: _vm.dialogVisible,
            width: "710px",
            "show-close": false,
            "close-on-click-modal": false,
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { padding: "0" },
              attrs: {
                rules: _vm.rules,
                model: _vm.formInline,
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { float: "left" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("searchModule.Equipment_name"),
                        prop: "equipmentName",
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "formWidth",
                        attrs: { maxlength: 20, placeholder: "请输入" },
                        model: {
                          value: _vm.formInline.equipmentName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "equipmentName", $$v)
                          },
                          expression: "formInline.equipmentName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("searchModule.Equipment_type"),
                        prop: "equipmentType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.formInline.equipmentType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "equipmentType", $$v)
                            },
                            expression: "formInline.equipmentType",
                          },
                        },
                        _vm._l(_vm.equipmentType, function (value) {
                          return _c("el-option", {
                            key: value.code,
                            attrs: { label: value.desc, value: value.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "设备功能", prop: "traceTag" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.functionChange },
                          model: {
                            value: _vm.formInline.traceTag,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "traceTag", $$v)
                            },
                            expression: "formInline.traceTag",
                          },
                        },
                        _vm._l(_vm.traceTag, function (value) {
                          return _c("el-option", {
                            key: value.code,
                            attrs: { label: value.desc, value: value.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { float: "left" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "设备编码", prop: "equipmentCode" } },
                    [
                      _c("el-input", {
                        staticClass: "formWidth",
                        attrs: { maxlength: 50, placeholder: "请输入" },
                        model: {
                          value: _vm.formInline.equipmentCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "equipmentCode", $$v)
                          },
                          expression: "formInline.equipmentCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "设备品牌", prop: "brandId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.formInline.brandId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "brandId", $$v)
                            },
                            expression: "formInline.brandId",
                          },
                        },
                        _vm._l(_vm.brandList, function (value) {
                          return _c("el-option", {
                            key: value.brandId,
                            attrs: {
                              label: value.brandFullName,
                              value: value.brandId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("searchModule.remarks"),
                        prop: "description",
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "formWidth",
                        attrs: { placeholder: "请输入", maxlength: 50 },
                        model: {
                          value: _vm.formInline.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "description", $$v)
                          },
                          expression: "formInline.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { clear: "both" } }),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.formInline.traceTag !== "",
                      expression: "formInline.traceTag !== ''",
                    },
                  ],
                  attrs: { label: "对应泊位号" },
                },
                [
                  _c("el-transfer", {
                    ref: "transfer",
                    attrs: {
                      "target-order": "push",
                      titles: ["未选择", "已选择"],
                      filterable: "",
                      "filter-placeholder": "请输入内容",
                      props: { label: "berthCode", key: "berthId" },
                      data: _vm.data2,
                    },
                    model: {
                      value: _vm.formInline.equipmentBerth,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "equipmentBerth", $$v)
                      },
                      expression: "formInline.equipmentBerth",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sure } },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }