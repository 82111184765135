<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          ref="form"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Pole_position_number')" prop="barCode">
                <el-input
                  v-model="formInline.barCode"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                type="primary"
                v-if="authority.button.add"
                icon="el-icon-plus"
                @click="addBerth"
                >{{ $t('button.addto') }}</el-button
              >
              <el-button
                type="info"
                v-if="authority.button.batchImport"
                plain
                icon="el-icon-upload"
                @click="openMuli"
                >批量{{ $t('button.import') }}</el-button
              >
              <el-button
                type="info"
                v-if="authority.button.delete"
                @click="multiDelete"
                >删除</el-button
              >
            </div>
            <div class="col_right">
              <el-button
                v-if="authority.button.batchExport"
                type="info"
                plain
                icon="el-icon-upload2"
                @click="exportFile"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          @selection-change="selectionChange"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <!-- <el-table-column type="index" :label="$t('list.index')" width="70" align='center'></el-table-column> -->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <el-table-column
            :label="this.$t('list.operation')"
            v-if="authority.button.view || authority.button.edit"
            align="center"
            width="80"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="authority.button.view" command="a"
                    >查看</el-dropdown-item
                  >
                  <el-dropdown-item v-if="authority.button.edit" command="b"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item v-if="authority.button.delete" command="c"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <add-berth
          @searchData="init"
          :monitorEquipment="monitorEquipment"
          :videoEquipment="videoEquipment"
          ref="addBerth"
        ></add-berth>
        <edit-berth
          @searchData="init"
          :monitorEquipment="monitorEquipment"
          :videoEquipment="videoEquipment"
          ref="editBerth"
        ></edit-berth>
        <berth-detail
          :monitorEquipment="monitorEquipment"
          :videoEquipment="videoEquipment"
          ref="berthDetail"
        ></berth-detail>
      </div>
      <upload-file
        ref="upload"
        @success="success"
        :uploadData="uploadData"
        :uploadUrl="uploadUrl"
        :modelUrl="modelUrl"
      ></upload-file>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { exportExcelNew } from "@/common/js/public";
import uploadFile from "@/components/uploadFile/";
import addBerth from "./add";
import editBerth from "./edit";
import berthDetail from "./Detail";
export default {
  name: "hello",
  props: ["authority"],
  data() {
    return {
      uploadUrl: "/acb/2.0/bar/import",
      modelUrl: "/acb/2.0/bar/downloadExcelTemplate",
      uploadData: {
        parkId: this.$route.query.parkId,
      },
      monitorEquipment: [],
      selection: [],
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      parkList: [],
      videoEquipment: [],
      tableCols: [
        {
          prop: "barCode",
          label: this.$t("list.Pole_position_number"),
          width: "",
        },
        {
          prop: "updatedTime",
          label: this.$t("list.Pole_position_number"),
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        barCode: "",
      },
    };
  },
  methods: {
    deleteBerth(data) {
      // console.log(data);
      this.$confirm("此操作将删除数据, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post(`/acb/2.0/bar/delete/${data.barId}`, {
              data: {
                // parkId: this.$route.query.parkId
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.page = 1;
                this.searchData();
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    success() {
      this.searchData();
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    multiDelete() {
      if (!this.selection.length) {
        this.$alert("请至少选择一条数据！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      let col = [];
      this.selection.forEach((v) => {
        col.push(v.barId);
      });
      this.$confirm("此操作将删除数据, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = "/acb/2.0/bar/delete/" + col.join(",");
          this.$axios
            .post(url, {
              data: {
                parkId: this.$route.query.parkId,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.page = 1;
                this.searchData();
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    addBerth() {
      this.$refs.addBerth.open();
    },
    selectionChange(selection) {
      this.selection = selection;
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$refs.berthDetail.getDetail(data);
        this.$refs.berthDetail.open();
      }
      if (cmd == "b") {
        this.$refs.editBerth.getDetail(data);
        this.$refs.editBerth.open();
      }
      if (cmd == "c") {
        this.deleteBerth(data);
      }
    },
    getVideo() {
      let url = "/acb/2.0/bar/listUnboundBarEquipments";
      this.$axios
        .get(url, {
          data: {
            parkId: this.$route.query.parkId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.value) {
              this.videoEquipment = res.value;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getMonitor() {
      let url = "/acb/2.0/monitorEquipment/listUnbindingMonitorEquipment";
      this.$axios
        .get(url, {
          data: {
            parkId: this.$route.query.parkId,
            page: 0,
            pageSize: 0,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.value) {
              this.monitorEquipment = res.value.list;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 搜索
    searchData() {
      this.loading = true;
      let url = "/acb/2.0/bar/list";
      this.$axios
        .get(url, {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            parkId: this.$route.query.parkId,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            if (res.value) {
              this.tableData = res.value.list;
              this.total = res.value.total * 1;
            } else {
              this.tableData = [];
              this.total = 0;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    init() {
      this.$refs.form && this.$refs.form.resetFields();
      this.getVideo();
      this.getMonitor();
      this.searchData();
    },
    exportFile() {
      exportExcelNew("/acb/2.0/bar/export", {
        parkId: this.$route.query.parkId,
      });
    },
    openMuli() {
      this.$refs.upload.clearFiles();
      this.$refs.upload.open();
    },
  },
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
  components: {
    addBerth,
    editBerth,
    berthDetail,
    uploadFile,
  },
  created() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    padding: 22px 22px 0;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}
</style>
