<template>
  <div>
    <el-dialog
      title="添加泊位"
      :visible.sync="dialogVisible"
      width="500"
      :show-close="false"
      custom-class="dialog"
    >
      <el-form ref="form" :model="form" label-width="120px" style="padding: 0">
        <el-form-item :label="$t('searchModule.Berth_number')">
          <span></span>
        </el-form-item>
        <el-form-item label="对应设备号">
          <el-transfer
            target-order="push"
            :titles="['Source', 'Target']"
            filterable
            filter-placeholder="请输入城市拼音"
            v-model="form.name"
            :data="data2"
          >
          </el-transfer>
        </el-form-item>
        <el-form-item label="计费规则">
          <el-transfer
            target-order="push"
            :titles="['Source', 'Target']"
            filterable
            filter-placeholder="请输入城市拼音"
            v-model="form.name"
            :data="data2"
          >
          </el-transfer>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible = false">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {},
      data2: [],
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
  },
};
</script>
<style>
.formWidth {
  width: 221.5px;
}

</style>
