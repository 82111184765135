<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <div style="text-align:right">
        <el-button @click="$router.go(-1)" type="primary">返回</el-button>
      </div>
    </div>
    <!--主体内容-->
    <div class="content bgFFF">
      <el-tabs v-model="activeName2" @tab-click="handleClick">
        <el-tab-pane v-if="authority.tabs['berth']" label="泊位管理" name="first">
          <berth-manage :authority="authority.tabs['berth']" ref="berth"></berth-manage>
        </el-tab-pane>
        <el-tab-pane
          v-if="authority.tabs['video']" label="数据采集设备管理" name="second">
          <video-equipment :authority="authority.tabs['video']" ref="equipment"></video-equipment>
        </el-tab-pane>
        <el-tab-pane v-if="authority.tabs['bar']" label="杆位管理" name="third">
          <pole-manage :authority="authority.tabs['bar']" ref="pole"></pole-manage>
        </el-tab-pane>
        <el-tab-pane v-if="authority.tabs['fee']" label="计费规则" name="fourth">
          <charge-rule :authority="authority.tabs['fee']" ref="charge"></charge-rule>
        </el-tab-pane>
        <!-- <el-tab-pane v-if="authority.tabs['monitor']" label="监控设备管理" name="five">
          <monitor-equip :authority="authority.tabs['monitor']" ref="monitor"></monitor-equip>
        </el-tab-pane> -->
        <el-tab-pane v-if="authority.tabs['parkPda']" label="收费员管理" name="six">
          <collector-manage :authority="authority.tabs['parkPda']" ref="collector"></collector-manage>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import collectorManage from "./parkSetChilds/collectorManage";
import berthManage from "./parkSetChilds/berthManage";
import chargeRule from "./parkSetChilds/chargeRule";
import poleManage from "./parkSetChilds/poleManage";
// import monitorEquip from "./parkSetChilds/monitorEquip";
import videoEquipment from "./parkSetChilds/videoEquipmentManage";
export default {
  name: "hello",
  data() {
    return {
      activeName2: "first",
      authority: this.$setAuthority("parkConfig")
    };
  },
  watch: {
    authority: {
      handler(ne, ol) {
        let key = Object.keys(ne.tabs)[0];
        if (Object.keys(ne.tabs)[0] == "video" && this.$route.query.parkEquipmentType == 4) {
          key = Object.keys(ne.tabs)[1];
        }
        this.$nextTick(() => {
          switch (key) {
            case "berth":
              this.$refs.berth.init();
              break;
            case "video":
              this.activeName2 = "second";
              this.$refs.equipment.init();
              break;
            case "bar":
              this.activeName2 = "third";
              this.$refs.pole.init();
              break;
            case "fee":
              this.activeName2 = "fourth";
              this.$refs.charge.init();
              break;
            case "monitor":
              this.activeName2 = "five";
              this.$refs.monitor.init();
              break;
            case "parkPda":
              this.activeName2 = "six";
              this.$refs.collector.init();
              break;
            default:
          }
        });
      },
      immediate: true
    }
  },
  methods: {
    handleClick(tab, event) {
      switch (tab.name) {
        case "first":
          this.$refs.berth.init();
          break;
        case "second":
          this.$refs.equipment.init();
          break;
        case "third":
          this.$refs.pole.init();
          break;
        case "fourth":
          this.$refs.charge.init();
          break;
        case "five":
          this.$refs.monitor.init();
          break;
        case "six":
          this.$refs.collector.init();
          break;
        default:
      }
    }
  },
  components: {
    berthManage,
    videoEquipment,
    poleManage,
    chargeRule,
    // monitorEquip,
    collectorManage
  },
  created() {},
  mounted() {
    // this.$refs.berth.init();
    // console.log(this.authority);
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
>>>.el-tabs__header
    padding-left 0px
    margin: 0
    .el-tabs__nav-wrap {
      height: 40px
      .el-tabs__nav-scroll {
      }
      .el-tabs__nav {
        height 40px
        .el-tabs__item {
          padding: 9px 16px
          height 100%
          color: #4e5969;
          &:hover, &.is-active {
            color: #0f6eff;
          }
        }
      }
    }
.breadcrumb
  margin: 8px 24px;
.content
  padding-left: 10px;
  overflow hidden
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    float right
    .export
      font-size 12px
      .iconfont
        margin-right 0
  .btnWrapper
    text-align left
    margin-top 28px
    float left
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
.transfer
  margin 0 auto
.button
  margin-bottom 15px
  text-align right
.ruleDetail
  width 500px
  height 150px
  overflow auto
  border 1px solid #bfcbd9
  border-radius 5px
  color #1f2d3d
.feeDis
  margin-left 20px
.berth-sort
  margin-top 10px
</style>
<style>
.el-table .success-row {
  background: #f0f9eb !important;
}
</style>
